export const CloseModal = () => {
    return(
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M6.08789 6.08883L1.66847 1.66942M1.66847 10.5083L6.08789 6.08883L1.66847 10.5083ZM6.08789 6.08883L10.5073 1.66942L6.08789 6.08883ZM6.08789 6.08883L10.5073 10.5083L6.08789 6.08883Z" 
                stroke="white" 
                strokeWidth="2.5" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </svg>
    )
}