import { FC } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { appReducer } from "store";
import { colors, GlobalStyle } from "design-system/theme";
import { Footer, Header, Layout } from "./design-system/components";

//Import Pages
import { ProviderPage } from "./pages/ProviderPage";
import { ProgramPage } from "./pages/ProgramPage/";
import {
	TermAndConditionPage,
	InterceptionAcceptanceRegulation,
} from "./pages/TermAndCondition";
import { LegacyExtractPage } from "./pages/LegacyExtractPage";
import { PageElo } from "./pages/PageElo";
import { RegisterPage } from "./pages/RegisterPage";
import { AuthPage } from "./pages/Auth";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Home } from "./pages/Home";
import { ProgramDetails } from "./pages/ProgramDetails";
import { LogoutPage } from "./pages/Logout";
import { MaintenancePage } from "./pages/Elo";

const App: FC = () => {
	const persistor = persistStore(appReducer);
	if (process.env.REACT_APP_CONECTAPONTOS_MAINTENANCE === "isMaintenance") {
		return <MaintenancePage />;
	}
	return (
		<Provider store={appReducer}>
			<PersistGate persistor={persistor}>
				<GlobalStyle />
				<Layout>
					<Header
						hideFirstRowHeader={false}
						backgroundColor={colors.generics.white}
					/>
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/distribuidores" element={<ProviderPage />} />
							<Route path="/programas-parceiros" element={<ProgramPage />} />
							<Route
								path="/programa/:programId/:id"
								element={<ProgramDetails />}
							/>
							<Route path="/regulamento" element={<TermAndConditionPage />} />
							<Route
								path="/resgatar"
								element={<InterceptionAcceptanceRegulation />}
							/>
							<Route path="/elo" element={<PageElo />} />
							<Route path="/extrato" element={<LegacyExtractPage />} />
							<Route path="/cadastro" element={<RegisterPage />} />
							<Route path="/logout" element={<LogoutPage />} />
							<Route path="auth" element={<AuthPage />} />
						</Routes>
					</BrowserRouter>
					<Footer />
				</Layout>
			</PersistGate>
		</Provider>
	);
};

export default App;
