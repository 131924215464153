/* eslint-disable @typescript-eslint/member-ordering */
import { StorageHelper } from "utils/functions/storage/storage";
import { HttpClient, HttpMethods } from "data/protocols/http/http-client";
import { ILoadLegacyExtract } from "data/protocols/services/extract/legacy-extract";

export class HttpLegacyExtract implements ILoadLegacyExtract {
  constructor(
    private readonly httpClient: HttpClient<
      ILoadLegacyExtract.Params,
      ILoadLegacyExtract.Result
    >,
    private readonly storageHelper: StorageHelper
  ) {}

  private headerSubscriptionKey = {
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${this.storageHelper.get("token")}`,
    ["Ocp-Apim-Subscription-Key"]: String(
      process.env.REACT_APP_SUBSCRIPTION_KEY
    ),
    ["X-Session-Id"]: this.storageHelper.get("sessionKey"),
    cutOffDate: process.env.REACT_APP_LEGACYDATE,
  };

  async getExtract(
    params?: ILoadLegacyExtract.Params
  ): Promise<ILoadLegacyExtract.RowsAndTotal> {
    const result = await this.httpClient.request({
      headers: this.headerSubscriptionKey,
      method: HttpMethods.GET,
      url: `${process.env.REACT_APP_BASE_URL_API}participant/participants/extract?documentNumber=${params?.data?.documentNumber}&transactionBeginDate=${params?.data?.startDate}&transactionEndDate=${params?.data?.endDate}`,
    });

    return {
      rows: result.response.data.rows,
      total: result.response.data.total,
    };
  }
}
