import { ContentStyled } from "./styled"

export const Content = () => {
    return(    
        <ContentStyled.Container id="modal-user-not-logged">
            <ContentStyled.ContentNotLogged translate="no">
                <h2>Ainda não é cadastrado?</h2>
                <p>Clique abaixo e crie sua conta.</p>
                <a rel="noreferrer"
					href={`${process.env.REACT_APP_CONECTA_AG_URL}/customer/account/create/`}
					target="_self">
                        Criar conta
                </a>
            </ContentStyled.ContentNotLogged>
            <ContentStyled.Divider>
                <img src="/img/divider.png" alt="arrow" />
            </ContentStyled.Divider>
            <ContentStyled.DividerMobile>
                <img src="/img/divider-mobile.png" alt="arrow" />
            </ContentStyled.DividerMobile>
            <ContentStyled.ContentNotLogged translate="no">
                <h2>Acessar minha conta</h2>
                <p>Se você já é cadastrado no conecta.ag acesse sua conta.</p>
                <a rel="noreferrer"
					href={`${process.env.REACT_APP_CONECTA_AG_URL}/customer/account/login?redirect=${"ConectaPontos"}`}
					target="_self">
                        Entrar
                </a>
            </ContentStyled.ContentNotLogged>
        </ContentStyled.Container>
    )
}