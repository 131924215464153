import { FC } from "react";
import { Checkbox as CheckboxUI ,FormControl, FormControlLabel } from "@mui/material";
import { colors } from "design-system/theme";
import { CheckboxProps, InputProps } from "design-system/domain/components";

const Checkbox: FC<InputProps & CheckboxProps> = ({
  checkboxCheckedColor,
  checkboxColor,
  checked,
  label,
  labelPosition,
  required,
  onChange
}) => {
  const checkBox = (): JSX.Element => {
    return (
      <CheckboxUI
        checked={checked}
        required={required}
        onChange={onChange}
        sx={{
          "&.Mui-checked": {
            color: checkboxCheckedColor ?? colors.primary.orange01,
          },
          color: checkboxColor ?? colors.primary.orange01,
        }}
      />
    );
  };

  return (
    <FormControl>
      <FormControlLabel
        control={checkBox()}
        label={label} 
        labelPlacement={labelPosition ?? "end"}
      />
    </FormControl>
  );
};

export default Checkbox;
