import styled from "styled-components";
import { breakpoints, colors } from "design-system/theme";
import { Paper, TableCell } from "@mui/material";

const ItemRow = styled.div`
  align-items: center;
  align-self: center;
  background-color: ${colors.generics.white};
  border-bottom: 1px solid ${colors.neutral.gray01};
  color: ${colors.generics.darkBlue};
  display: flex;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  justify-content: center;
  text-align: center;
  width: 50%;

  &:nth-child(odd) {
    background-color: ${colors.generics.grayLight};
  }

  &:hover {
    opacity: 0.9;
  }

  ${breakpoints.down("sm")} {
    width: 90%;
  } ;
`;


const Container = styled.div`
  display: flex;
  justify-content: center;
`

const PaperCustomized = styled(Paper)`
  cursor: pointer;
  height: 100vh;
  overflow: auto;
  width: 100%;

  ${breakpoints.up('lg')} {
    height: 55vh;
    width: 50%;
  }
  `

const TitleColumns = styled.p`
  color: rgb(243, 149, 0);
  font-size: 15px;
  font-weight: 800;
`

const TableCellCustomized = styled(TableCell)`
  background: rgb(254, 243, 234);
  min-width: 40px;
`
export const StackStyled = {
  Container,
  ItemRow,
  PaperCustomized,
  TableCellCustomized,
  TitleColumns
}
