import styled from "styled-components";
import { Button } from "design-system/components";
import { colors } from "design-system/theme/colors";

const Container = styled.div`
	display: grid;
	place-items: center;
	justify-content: center;
	align-content: center;
	grid-template-columns: 39% 12% 39%;
	padding: 0rem 1.75rem;

	h2 {
		font-family: 'AristotelicaExtraBold', sans-serif;
		font-size: 32px;
		font-weight: 700;
		line-height: 38.4px;
		text-align: center;
		color: #FF002C;
	}

	@media (max-width: 800px) {
		display: block;
		margin-top: 1.5rem;
	}
`;

const ContentNotLogged = styled.div`
	width: 70%;

	p {
		font-family: 'ComfortaaLight', sans-serif;
		font-size: 12px;
		line-height: 18px;
		text-align: center;
		color: #6C6D70;

		@media (max-width: 800px) {
			width: 80%;
			margin: auto;
		}

	}

	a {
		display: block;
		background-color: #FF002C;
		height: 20px;
		border-radius: 24px;
		padding: 7.91px;
		margin-top: 1.5rem;

		font-family: 'ComfortaaBold', sans-serif;
		font-size: 14px;
		font-weight: 700;
		line-height: 22px;
		text-align: center;
		color: #F9F9F9;

		cursor: pointer;

		:hover {
			background: linear-gradient(90deg, #e6072e 0%, #ff002c .04%, #f58224 100%);
			transition: 0.4s ease all;
		}
	}

	@media (max-width: 800px) {
		width: 100%;
	}
`;

const Divider = styled.div`
	margin-top: -1rem;

	img {
		image-rendering: pixelated;
	}

	@media (max-width: 800px) {
		display: none;
	}
`;

const DividerMobile = styled.div`
	img {
		width: 100%;
		margin: 1rem auto;
		image-rendering: pixelated;
	}

	@media (max-width: 800px) {
		display: block;
	}
	
	@media (min-width: 800px) {
		display: none;
	}
`;

const CloseButtom = styled.a`
    background-color: rgb(253, 19, 43);
    border-radius: 50%;
    color: rgb(255, 255, 255);
    margin-top: 1rem;
    margin-right: 1rem;
    height: 2.4rem;
    width: 2.4rem;

    cursor: pointer;

	svg {
		width: 13px;
		height: 13px;
		padding: 0.75rem;

		@media (min-width: 800px) {
			width: 12px;
			height: 12px;
			padding: 0.8rem;
		}
	}

	:hover {
		background: linear-gradient(90deg, #e6072e 0%, #ff002c .04%, #f58224 100%);
		transition: 0.4s ease all;
	}
`;

const Title = styled.h2`
	color: #fd132b;
`;
const SubTitleSite = styled.span`
	color: #fd132b;
	font-weight: 800;
	text-decoration: underline;
`;

const HeaderRow = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const LogoHeaderModal = styled.img`
	height: 20px;
`;

const LinksSteps = styled.a`
	color: #fd132b;
	text-decoration: underline;
`;

const ModalFooter = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 20px;
`;

const CustomCancelButton = styled(Button)`
	background-color: ${colors.generics.grayLight};
	color: ${colors.generics.darkBlue};

	&:hover {
		color: ${colors.generics.white};
	}
`;

const CustomConfirmButton = styled(Button)`
	background-color: #fd132b;
	border-radius: 20px;
	width: 200px;

	&:hover {
		background-color: #ff2e44;
	}
`;

const ModalBody = styled.div`
	display: block;
	margin-top: 20px;
	padding: 10px 25px;
`;

const RowButton = styled.div`
	margin-top: 35px;
	text-align: center;
	margin-bottom: 25px;
`;

const ListInstructions = styled.ul`
	list-style-type: disc;
	margin-bottom: 10px;
	padding: 0 30px;
`;

export const UserNotLoggedStyled = {
	CloseButtom,
	CustomCancelButton,
	CustomConfirmButton,
	HeaderRow,
	LinksSteps,
	ListInstructions,
	LogoHeaderModal,
	ModalBody,
	ModalFooter,
	RowButton,
	SubTitleSite,
	Title,
};

export const ContentStyled = {
	Container,
	ContentNotLogged,
	Divider,
	DividerMobile
}
