import { FC, useEffect } from 'react'
import { useTitle } from "hooks";
import { LogoutPageStyled } from './styled';
import { logoutUser } from 'utils/functions/logout-user';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const LogoutPage: FC = () => {
    const { user } = useSelector((state: RootState) => state);

    useTitle("Encerrando sessão");

    useEffect(() => {
        if (user.document_number) {
            logoutUser(user.document_number)
        }
    }, [user?.document_number])

    return (
        <LogoutPageStyled.Container>
            <LogoutPageStyled.BoxLoading>
                <LogoutPageStyled.CircularLoading />
            </LogoutPageStyled.BoxLoading>
            <LogoutPageStyled.LabelCheckTokenStatus>
                Estamos encerrando sua sessão.
            </LogoutPageStyled.LabelCheckTokenStatus>
        </LogoutPageStyled.Container>
    )
}

export default LogoutPage