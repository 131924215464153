import logoConectaAg from "./conecta-ag.svg";
import logoAgrega from "./logo-agrega.png";
import logoConecta from "./logo-conecta-ag.png";
import logoUser from "./logo-user.svg";

export const HeaderImages = {
	logoAgrega,
	logoConecta,
	logoConectaAg,
	logoUser,
};
