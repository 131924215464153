export const env = {
	API_ENDPOINT_TIMEOUT: String(process.env.REACT_APP_API_ENDPOINT_TIMEOUT),
	APP_LEGACYDATE: String(process.env.REACT_APP_LEGACYDATE),
	APP_WEBSITE_TITLE: String(process.env.REACT_APP_WEBSITE_TITLE),
	BASE_URL_API: String(process.env.REACT_APP_BASE_URL_API),
	NODE_ENV: String(process.env.NODE_ENV),
	PARTICIPANT_KEY: String(process.env.REACT_APP_PARTICIPANT_KEY),

	REACT_APP_AGREGA_SUBSCRIPTION_KEY: String(
		process.env.REACT_APP_AGREGA_SUBSCRIPTION_KEY
	),
	SUBSCRIPTION_KEY: String(process.env.REACT_APP_SUBSCRIPTION_KEY),
};
