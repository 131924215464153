import ContentLoader from "react-content-loader";

const Loader = () => (
	<>
		<ContentLoader
			speed={2}
			width={"100%"}
			height={600}
			style={{ width: "100%" }}
			viewBox="0 0 100% 500"
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
		>
			<circle cx="25" cy="295" r="15" />
			<rect x="51" y="284" rx="2" ry="2" width="100%" height="10" />
			<rect x="51" y="303" rx="2" ry="2" width="100%" height="10" />
			<rect x="11" y="5" rx="2" ry="2" width="100%" height="250" />

			<circle cx="25" cy="355" r="15" />
			<rect x="51" y="340" rx="2" ry="2" width="100%" height="10" />
			<rect x="51" y="360" rx="2" ry="2" width="100%" height="10" />

			<circle cx="25" cy="410" r="15" />
			<rect x="51" y="395" rx="2" ry="2" width="100%" height="10" />
			<rect x="51" y="415" rx="2" ry="2" width="100%" height="10" />
		</ContentLoader>
	</>
);

export default Loader;
