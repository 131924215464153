import decode from "jwt-decode";

export interface IJwtAdapter {
	customDecode: (token: string) => JwtAdapter.DecodeResult;
}
export class JwtAdapter implements IJwtAdapter {
	customDecode(token: string): JwtAdapter.DecodeResult {
		const decodeResult = decode<JwtAdapter.DecodeResult>(token);
		return decodeResult;
	}
}

export namespace JwtAdapter {
	export type DecodeResult = {
		SessionKey: string | null
		aud: string | null;
		documentNumber: string | null;
		email: string | null;
		exp: number | null;
		iat: number | null;
		iss: string | null;
		name: string | null;
		nbf: number | null;
		optInAgrega?: string | null;
		optInPoints: string | null;
		optinComunication: boolean | null | number;
		optinTermsAndPrivacy: string | null;
		points: number | null;
		promotionCode: string | null;
		token: string | null;
	};
}
