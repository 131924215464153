import { FC, useEffect, useState } from "react";
import { Http } from "data/protocols/services/api";
import { useTitle } from "hooks";
import { StatusRequest } from "utils";
import { ProviderPageStyled } from "./styled";
import { MProviderList } from "domain/models/provider/list";
import { HttpLoadProvidersList } from "services/provider/load-providers-list";
import {
	SearchForm,
	ContainerPage,
	TitlePage,
	TableList,
} from "design-system/components";

type PaginationProps = {
	pageIndex: number;
	pageSize: number;
	totalItems: number;
};

const ProviderPage: FC = () => {
	const [paginationProps, setPaginationProps] = useState<PaginationProps>({
		pageIndex: 0,
		pageSize: 10,
		totalItems: 0,
	});
	const [customParam, setCustomParam] = useState<{
		[key: string]: string | number;
	} | null>(null);
	const [providers, setProviders] = useState<MProviderList[] | null>(null);
	const [statusRequest, setStatusRequest] = useState<null | string>(null);

	useTitle("Lista de distribuidores");

	const http = new Http();
	const httpLoadProvidersList = new HttpLoadProvidersList(http);

	const fetchProviders = async (pageIndex: number): Promise<void> => {
		try {
			setStatusRequest(StatusRequest.FETCHING);
			let httpParams: { [key: string]: string | number } = {
				pageIndex,
				pageSize: paginationProps.pageSize,
			};

			if (customParam) {
				httpParams = {
					...httpParams,
					...customParam,
				};
			}

			httpLoadProvidersList.load(httpParams).then((result) => {
				setPaginationProps({
					...paginationProps,
					pageIndex:
						pageIndex === 0
							? 10
							: paginationProps.pageIndex + paginationProps.pageSize,
					totalItems: result.response.data.total,
				});
				setProviders(result.response.data.rows);
				setStatusRequest(StatusRequest.SUCCESS);
			});
		} catch {
			setStatusRequest(StatusRequest.ERROR);
		}
	};

	const nextPageAction = () => {
		fetchProviders(paginationProps.pageIndex);
	};

	const lastPageAction = () => {
		fetchProviders(paginationProps.totalItems - paginationProps.pageIndex);
	};

	const firstPageAction = () => {
		fetchProviders(0);
	};

	const prevPageAction = () => {
		fetchProviders(
			paginationProps.pageIndex - paginationProps.pageSize * 2 < 0
				? 0
				: paginationProps.pageIndex - paginationProps.pageSize * 2
		);
	};

	useEffect(() => {
		if (!providers?.length) {
			fetchProviders(paginationProps.pageIndex);
		}
	}, [providers]);

	const updateCustomParamValue = (customParam: {
		[key: string]: string | number;
	}) => {
		setCustomParam(customParam);
	};

	return (
		<>
			<ContainerPage>
				<TitlePage title="Distribuidores" />
				<ProviderPageStyled.ContainerSubTitleText>
					<ProviderPageStyled.SubTitleText>
						Confira nossos distribuidores oficiais:
					</ProviderPageStyled.SubTitleText>
				</ProviderPageStyled.ContainerSubTitleText>
				<SearchForm
					fetchProviders={fetchProviders}
					setCustomParam={updateCustomParamValue}
				/>
				<TableList
					data={{
						columnTitles: [
							"UF",
							"Distribuidor",
							"Cidade",
							"Bairro",
							"Logradouro",
							"Número",
						],
						rows: providers?.map((p) => ({
							["Bairro"]: p.neighborhood,
							["Cidade"]: p.city,
							["Distribuidor"]: p.name,
							["Logradouro"]: p.address,
							["Número"]: p.number,
							["UF"]: p.state,
						})),
						totalItems: paginationProps.totalItems,
					}}
					fetching={{
						fetching: statusRequest === StatusRequest.FETCHING,
					}}
					error={{
						hasError: statusRequest === StatusRequest.ERROR,
					}}
					paginationActions={{
						firstPageAction: firstPageAction,
						lastPageAction: lastPageAction,
						nextPageAction: nextPageAction,
						prevPageAction: prevPageAction,
					}}
				/>
			</ContainerPage>
		</>
	);
};

export default ProviderPage;
