import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { colors } from "design-system/theme";

const TermAndConditionAccordionProgram = (): JSX.Element => (
    <>
        <Accordion
            TransitionProps={{ unmountOnExit: true }}
            sx={{ backgroundColor: colors.neutral.gray03, color: colors.generics.white, marginBottom: "15px" }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: colors.generics.white }} />}
                aria-controls="panel1a-content"
                id="panel1"
            >
                <Typography style={{
                    fontSize: "20px",
                    fontWeight: "600"
                }}>Programa</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                </Typography>
            </AccordionDetails>
        </Accordion>
    </>
)

const TermAndConditionAccordionRescue = (): JSX.Element => (
    <>
        <Accordion
            TransitionProps={{ unmountOnExit: true }}
            sx={{ backgroundColor: colors.primary.orange02, color: colors.generics.white, marginBottom: "15px" }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: colors.generics.white }} />}
                aria-controls="panel1a-content"
                id="panel2"
            >
                <Typography style={{
                    fontSize: "20px",
                    fontWeight: "600"
                }}>Resgate</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                </Typography>
            </AccordionDetails>
        </Accordion>
    </>
)

const TermAndConditionAccordionRulesAndScore = (): JSX.Element => (
    <>
        <Accordion
            TransitionProps={{ unmountOnExit: true }} sx={{ backgroundColor: colors.neutral.gray03, color: colors.generics.white, marginBottom: "15px" }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: colors.generics.white }} />}
                aria-controls="panel1a-content"
                id="panel3"
            >
                <Typography style={{
                    fontSize: "20px",
                    fontWeight: "600"
                }}>Regras de Pontuação
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                </Typography>
            </AccordionDetails>
        </Accordion>
    </>
)

const TermAndConditionAccordionExpirationScore = (): JSX.Element => (
    <>
        <Accordion
            TransitionProps={{ unmountOnExit: true }}
            sx={{ backgroundColor: colors.primary.orange02, color: colors.generics.white, marginBottom: "15px" }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: colors.generics.white }} />}
                aria-controls="panel1a-content"
                id="panel4"
            >
                <Typography style={{
                    fontSize: "20px",
                    fontWeight: "600"
                }}>Expiração dos Pontos</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                </Typography>
            </AccordionDetails>
        </Accordion>
    </>
)

const TermAndConditionAccordionMigrationScore = (): JSX.Element => (
    <>
        <Accordion
            TransitionProps={{ unmountOnExit: true }}
            sx={{ backgroundColor: colors.neutral.gray03, color: colors.generics.white, marginBottom: "15px" }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: colors.generics.white }} />}
                aria-controls="panel1a-content"
                id="panel5"
            >
                <Typography style={{
                    fontSize: "20px",
                    fontWeight: "600"
                }}>Migração de Pontos do Antigo Programa</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                </Typography>
            </AccordionDetails>
        </Accordion>
    </>
)

const TermAndConditionAccordion = () => {
    return (
        <>
            {TermAndConditionAccordionProgram()}
            {TermAndConditionAccordionRescue()}
            {TermAndConditionAccordionRulesAndScore()}
            {TermAndConditionAccordionExpirationScore()}
            {TermAndConditionAccordionMigrationScore()}
        </>
    )
}

export default TermAndConditionAccordion