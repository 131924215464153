import { FC, Fragment } from "react";
import { ModalProps } from "design-system/domain/components";
import { ModalStyled } from "./styled";

const Modal: FC<ModalProps> = ({
  boxShadow,
  title,
  subTitle,
  content,
  header,
  titleAlign,
  open,
  size,
  zindex,
  style
}) => {
  if (open) {
    return (
      <>
        <ModalStyled.PageDisabled />
        <ModalStyled.Container
          style={style}
          boxShadow={boxShadow}
          zindex={zindex}
          size={size}
        >
          {header && header}
          {title && (
            <ModalStyled.Title titleAlign={titleAlign}>
              {title}
            </ModalStyled.Title>
          )}

          {subTitle && <p>{subTitle}</p>}
          {content}
        </ModalStyled.Container>
      </>
    );
  } else {
    return <Fragment />;
  }
};
export default Modal;
