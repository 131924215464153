import React, { FC, SyntheticEvent, useEffect, useState } from "react";
import { Box, FormControl } from "@mui/material";
import { SearchFormStyled } from "./styled";

const SearchForm: FC<{
  fetchProviders: (pageIndex: number) => Promise<void>;
  setCustomParam: any;
}> = ({ fetchProviders, setCustomParam }) => {
  const [selectValue, setSelectValue] = useState<string>("");
  const [inputSearch, setInputSearch] = useState<string>("");
  const [clearForm, setClearForm] = useState<boolean>(false);

  const filterProvidersByParam = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault();
    fetchProviders(0);
  };

  const handleClearForm = (e: SyntheticEvent): string | void => {
    e.preventDefault();
    setClearForm(false);
    setInputSearch("");
  };

  useEffect(() => {
    setCustomParam({
      [selectValue]: inputSearch,
    });
  }, [selectValue, inputSearch]);

  return (
    <SearchFormStyled.Container>
      <Box sx={{ minWidth: 120 }}>
        <FormControl
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
          fullWidth
        >
          <SearchFormStyled.SelectSearchProvider
            value={selectValue}
            disabled={clearForm}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setSelectValue(e.target.value)
            }
          >
            <SearchFormStyled.OptionSelectSearchProvider value="">
              Buscar por:
            </SearchFormStyled.OptionSelectSearchProvider>
            <SearchFormStyled.OptionSelectSearchProvider value="Name">
              Distribuidor
            </SearchFormStyled.OptionSelectSearchProvider>
            <SearchFormStyled.OptionSelectSearchProvider value="City">
              Cidade
            </SearchFormStyled.OptionSelectSearchProvider>
            <SearchFormStyled.OptionSelectSearchProvider value="State">
              UF
            </SearchFormStyled.OptionSelectSearchProvider>
          </SearchFormStyled.SelectSearchProvider>
          <SearchFormStyled.InputSearchContainer>
            <SearchFormStyled.InputSearchProvider
              placeholder="Buscar por distribuidores"
              value={inputSearch}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setInputSearch(e.target.value);
              }}
              disabled={clearForm}
              maxLength={inputSearch === "state" ? 2 : 99}
            />
            {selectValue && !inputSearch && (
              <SearchFormStyled.FormSpanError>
                * Preenchimento obrigatório.
              </SearchFormStyled.FormSpanError>
            )}
          </SearchFormStyled.InputSearchContainer>
          {!clearForm ? (
            <SearchFormStyled.SearchProviderButton
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                filterProvidersByParam(e)
              }
            >
              Pesquisar
            </SearchFormStyled.SearchProviderButton>
          ) : (
            <SearchFormStyled.CleanSearchFormButton onClick={handleClearForm}>
              Limpar
            </SearchFormStyled.CleanSearchFormButton>
          )}
        </FormControl>
      </Box>
    </SearchFormStyled.Container>
  );
};
export default SearchForm;
