import { CloseModal } from "design-system/icons/geral-icons/closeModal";
import { UserNotLoggedStyled } from "./styled";
import { ModalUserIsNotLoggedProps } from "./types";

export const Header = ({handleCloseModal}: ModalUserIsNotLoggedProps) => {
    return (
        <UserNotLoggedStyled.HeaderRow>
            <UserNotLoggedStyled.CloseButtom id="close-modal" onClick={() => handleCloseModal()}>
                <CloseModal />
            </UserNotLoggedStyled.CloseButtom>
        </UserNotLoggedStyled.HeaderRow>
    );
}