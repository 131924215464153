import { Colors } from 'design-system/domain'

export const colors: Colors = {
    conectaTheme: {
        primary: '#e6072e',
        secondary: '#ff002c',
        tertiary: '#f58224',
        userBorderComponent: 'rgba(251, 210, 175, 1)',
        userComponent: 'rgb(254 243 234)'
    },
    generics: {
        darkBlue: '#212529',
        gray: '#d9d9d9',
        grayLight: '#F2F2F2',
        white: '#FFF'
    },
    neutral: {
        backgroundTwo: "#FEF3EA",
        black1: "#3C3C3C",
        cardBox: "#AAAAAA",
        gray01: "#555555",
        gray02: "#6C6D70",
        gray03: "#7B7B7B",
        gray04: "#F2F2F2",
        gray05: '#969696',
        gray06: '#3C3C3C',
        gray07: '#706F6F',
        gray08: '#495057',
        gray09: '#CED4DA',
        gray10: "#808080",
        gray11: "#F7F7F7",
        gray12: '#BBB6B6',
        gray13: '#6E6666',
        outlineCards: "#FEF3EA",
    },
    primary: {
        cian: "#16e7ff",
        dark: "#282525",
        gradient: `linear-gradient(90deg, #e6072e 0%, #ff002c .04%, #f58224 100%)`,
        green: "#428603",
        orange01: "#F58224",
        orange02: "#F39500",
        orange03: "#DD7521",
        orange04: "#FFBE58",
        red: "#EC0818",
    },
    status: {
        error: "#A71E3D",
        error2: '#ED7161',
        success: "#0CAD0F",
        warning: "#FAB519",
    }
}