import { FC } from "react";
import { LayoutContainerStyled } from './styled'
import { LayoutProps } from "design-system/domain";
import { blockRoutesToShowElement } from "utils/consts/blocked-routes-show-header-and-footer";

const Layout: FC<LayoutProps> = ({ children }) => (
  <LayoutContainerStyled.Layout isBlockedPage={blockRoutesToShowElement.includes(window.location.pathname)}>
    {children}
  </LayoutContainerStyled.Layout>
)

export default Layout;