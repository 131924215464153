import { FC, Fragment } from "react";
import { CloseIcon } from "design-system/icons";
import { Modal } from "design-system/components";

import { UserNotLoggedStyled } from "./styled";

export type ModalUserIsLoggedProps = {
	handleCloseModal: () => void;
	isOpen: boolean;
	queryParamValue: string;
};

const ModalUserIsLogged: FC<ModalUserIsLoggedProps> = ({
	handleCloseModal,
	isOpen,
	queryParamValue,
}) => (
	<Modal
		style={{
			padding: 0,
			width: 510,
		}}
		header={
			<UserNotLoggedStyled.HeaderRow>
				<UserNotLoggedStyled.CloseButtom onClick={() => handleCloseModal()}>
					<CloseIcon />
				</UserNotLoggedStyled.CloseButtom>
			</UserNotLoggedStyled.HeaderRow>
		}
		content={
			<>
				<UserNotLoggedStyled.ModalBody>
					<UserNotLoggedStyled.ListInstructions>
						<p>Você já está cadastrado!</p>
					</UserNotLoggedStyled.ListInstructions>
				</UserNotLoggedStyled.ModalBody>
			</>
		}
		titleAlign={`center`}
		open={isOpen}
		size={`MEDIUM`}
	/>
);

export default ModalUserIsLogged;
