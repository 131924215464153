import { breakpoints, colors } from "design-system/theme";
import styled from "styled-components";

const Container = styled.div``

const ContainerSubTitleText = styled.section`
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    max-width: 1000px;
    max-height: 100%;
    margin: 0;
    padding: 0 0 50px 175px;    
    width: 100%;
    box-sizing: border-box;
    
    ${breakpoints.down(600)} {
    padding: 0 0 20px 75px;  
    }
`;
const Message = styled.p`
    /* text-align: center; */
    padding: 20px 20px 20px 150px;
    background-color: ${colors.neutral.gray04};
`

const SubTitleText = styled.p`
    color: ${colors.neutral.gray03};
    display: block;
    font-size: 16px;
    line-height: 1.3;
    width: 100%;

    ${breakpoints.down("sm")} {
        font-size: 17px;
    }
`;

export const ProviderPageStyled = {
    Container,
    ContainerSubTitleText,
    Message,
    SubTitleText,
}