import { createGlobalStyle } from "styled-components";

import Aristotelica from "./fonts/Aristotelica-Regular.ttf";
import AristotelicaExtraLight from "./fonts/Aristotelica-ExtraLight.ttf";
import AristotelicaBold from "./fonts/Aristotelica-Bold.ttf";
import AristotelicaExtraBold from "./fonts/Aristotelica-ExtraBold.ttf";

import Comfortaa from './fonts/Comfortaa-Regular.ttf';
import ComfortaaBold from './fonts/Comfortaa-Bold.ttf';
import ComfortaaLight from './fonts/Comfortaa-Light.ttf';

import PoppinsBold from "./fonts/Poppins-SemiBold.ttf";
import PoppinsExtraBold from "./fonts/Poppins-ExtraBold.ttf";
import PoppinsMedium from "./fonts/Poppins-Medium.ttf";
import PoppinsLight from "./fonts/Poppins-Light.ttf";

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: "Aristotelica";
        src: url(${Aristotelica});
    }

    @font-face {
        font-family: 'AristotelicaExtraLight';
        src: url(${AristotelicaExtraLight});
    }

    @font-face {
        font-family: 'AristotelicaBold';
        src: url(${AristotelicaBold});
    }

    @font-face {
        font-family: 'AristotelicaExtraBold';
        src: url(${AristotelicaExtraBold});
    }

    @font-face {
        font-family: 'Comfortaa';
        src: url(${Comfortaa});
    }

    @font-face {
        font-family: 'ComfortaaBold';
        src: url(${ComfortaaBold});
    }

    @font-face {
        font-family: 'ComfortaaLight';
        src: url(${ComfortaaLight});
    }

    @font-face {
        font-family: 'PoppinsExtraBold';
        src: url(${PoppinsExtraBold});
    }

    @font-face {
        font-family: 'PoppinsBold';
        src: url(${PoppinsBold});
    }
    
    @font-face {
        font-family: 'PoppinsLight';
        src: url(${PoppinsLight});
    }

    @font-face {
        font-family: 'PoppinsMedium';
        src: url(${PoppinsMedium});
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    ul {
        list-style-type: none;
    }

    body { 
        font-family: 'PoppinsLight', 'Arial', sans-serif;
        margin: 0;
        outline: none;
    }

    * {
        outline: none;
        margin: 0;
        padding: 0;
    }
`;
