/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient, HttpMethods } from "data/protocols/http/http-client";
import { StorageHelper } from "utils/functions/storage/storage";
import { IRegistrationRedirect } from "data/protocols/services/account/redirect";

export class HttpRegistrationRedirect implements IRegistrationRedirect {
	constructor(
		private readonly httpClient: HttpClient<
			IRegistrationRedirect.Params,
			IRegistrationRedirect.Result
		>,
		private readonly storageHelper: StorageHelper
	) {}

	private headerSubscriptionKey = {
		"Access-Control-Allow-Origin": "*",
		Authorization: `Bearer ${this.storageHelper.get("token")}`,
		["Ocp-Apim-Subscription-Key"]: String(
			process.env.REACT_APP_AGREGA_SUBSCRIPTION_KEY
		),
		["X-Session-Id"]: this.storageHelper.get("sessionKey"),
	};

	async getUrlRedirect(
		params?: IRegistrationRedirect.Params
	): Promise<IRegistrationRedirect.Result> {
		const result = await this.httpClient.request({
			data: params?.data,
			headers: this.headerSubscriptionKey,
			method: HttpMethods.POST,
			url: `${process.env.REACT_APP_BASE_URL_API}participant/participants/redirectCatalog`,
		});

		return result as unknown as IRegistrationRedirect.Result;
	}
}
