import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ptBR } from '@mui/material/locale';
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt"
import { CustomInputDate } from './Style'
import "react-datepicker/dist/react-datepicker.css";

registerLocale("pt", pt)

const theme = createTheme(
    ptBR,
);

const CustomDate = ({ onChange, ...props }) => {
  return (
        <ThemeProvider theme={theme}>
            <CustomInputDate.Container>
                {props.labelText &&
                    <CustomInputDate.Label htmlFor={props.id}>
                        {props.labelText}
                        {props.required && <span className='required'>*</span>}
                    </CustomInputDate.Label>
                }
                <DatePicker
                    {...props}
                    id={props.id}
                    locale='pt'
                    dateFormat="PP"
                    onChange={(date) => onChange(date, props.name)}
                    customInput={
                        <CustomInputDate.InputDate
                            type='text'
                            readOnly={true}
                        />
                    }
                />

            </CustomInputDate.Container>
        </ThemeProvider >
    );
};

export default CustomDate;