import { FC, useState } from "react";
import {
  TableRow,
  TablePagination,
  TableFooter,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ptBR } from "@mui/material/locale";
import { TableStyled } from "./styled";
import TablePaginationActions from "./TablePaginationAction";
import { EventsTable, TableListProps } from "design-system/domain";

const TableList: FC<TableListProps> = (props) => {
  const [page, setPage] = useState<number>(0);
  const theme = createTheme(ptBR);

  const { data } = props;

  const handleChangePage = (
    _e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    const event = detectEvent(page, newPage);
    switch (event) {
      case EventsTable.NEXT_PAGE:
        setPage(newPage);
        return (
          props?.paginationActions?.nextPageAction &&
          props.paginationActions.nextPageAction()
        );
      case EventsTable.LAST_PAGE:
        setPage(newPage);
        return (
          props?.paginationActions?.lastPageAction &&
          props.paginationActions.lastPageAction()
        );
      case EventsTable.FIRST_PAGE:
        setPage(newPage);
        return (
          props?.paginationActions?.firstPageAction &&
          props.paginationActions.firstPageAction()
        );
      case EventsTable.PREV_PAGE:
        setPage(newPage);
        return (
          props?.paginationActions?.prevPageAction &&
          props?.paginationActions?.prevPageAction()
        );
    }
  };

  const detectEvent = (page: number, newPage: number): string => {
    if (newPage - 1 === page) {
      return EventsTable.NEXT_PAGE;
    }

    if (page - 1 === newPage) {
      return EventsTable.PREV_PAGE;
    }

    if (newPage === 0) {
      return EventsTable.FIRST_PAGE;
    }

    return EventsTable.LAST_PAGE;
  };

  const renderTableContent = (): JSX.Element => {
    if (props?.fetching?.fetching) {
      if (props?.fetching?.cutomFetchingMessage) {
        return <>{props?.fetching?.cutomFetchingMessage}</>;
      } else {
        return (
          <TableStyled.LoadingContainer>
            <p>Carregando...</p>
          </TableStyled.LoadingContainer>
        );
      }
    }

    if (props?.error?.hasError) {
      if (props?.error?.hasError) {
        return <>{props?.error?.hasError}</>;
      } else {
        return <p>Ocorreu um erro ao obter os dados</p>;
      }
    }

    return (
      <ThemeProvider theme={theme}>
        <TableHead>
          <tr>
            {data?.columnTitles?.map((columTitle: string, key: number) => (
              <TableStyled.StyledTableHead key={key}>
                {columTitle}
              </TableStyled.StyledTableHead>
            ))}
          </tr>
        </TableHead>
        <TableBody>
          {data?.rows?.map((item, k: number) => {
            return (
              <TableStyled.StyledTableRow key={k}>
                {data.columnTitles?.map((column: string, key: number) => {
                  return (
                    <TableStyled.StyledTableCell key={key}>
                      {item[column]}
                    </TableStyled.StyledTableCell>
                  );
                })}
              </TableStyled.StyledTableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={data?.columnTitles?.length}
              count={props.data?.totalItems as number}
              rowsPerPage={props.data?.rows?.length as number}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "Linhas por página",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </ThemeProvider>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <TableStyled.TableLess>
        <TableContainer component={Paper}>
          <Table>{renderTableContent()}</Table>
        </TableContainer>
      </TableStyled.TableLess>
    </ThemeProvider>
  );
};

export default TableList;
