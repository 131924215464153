import styled from "styled-components";
import { CropSquare, FacebookOutlined } from '@mui/icons-material'
import { breakpoints, colors } from "design-system/theme";
import { ContainerItemsDesktop } from 'design-system/domain'

const Container = styled.footer`
    background-color: ${colors.generics.white};
    border: 0;
    height: auto;
    margin: 45px auto 0;
    
    &::before {
        content: '';
        background: ${colors.primary.gradient};
        display: block;
        height: 8px;
        width: 100%;
    }
`

const ConectaTitle = styled.h3`
    color: ${colors.conectaTheme.primary};
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 1px;
    text-transform: uppercase;
    margin: 25px 0 20px;
`

const ContainerLinks = styled.ul`
    display: flex;
    flex-direction: column;
`

const ContainerItemAndSquare = styled.li`
    margin-top: 5px;
`
const LinkItems = styled.a`
    color: ${colors.neutral.black1};
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
    margin-top: 5px;

    &:hover {
        text-decoration: underline;
    } 
`

const CropSquareCustomized = styled(CropSquare)`
    fill: ${colors.neutral.black1} !important;
    font-size: 11px !important;
    margin: 12px 14px 0 0;
`

const ConectaTitleMobile = styled(ConectaTitle)`
    margin-top: 60px;

    ${breakpoints.up('sm')} {
        margin-top: revert;
    }
`

const ChannelsTitle = styled(ConectaTitle)``

const ContactsConecta = styled.p`
    color: ${colors.neutral.black1};
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0;
`

const ContainerIcons = styled.div`
    margin-top: 40px;

    ${breakpoints.down("sm")} {
        margin-bottom: 20px;
    }
`
const FacebookOutlinedCustomized = styled(FacebookOutlined)`
    cursor: pointer;
    fill: ${colors.neutral.black1} !important;
    font-size: 45px !important;
`

const IconOutlinedCustomized = styled.img`
    cursor: pointer;
    height: 45px;
    margin-left: 15px;
`

const FacebookLogoFooter = styled(IconOutlinedCustomized)`
    height: 30px;
    width: 18px;
    margin-left: 7px;

    ${breakpoints.down("sm")} {
        margin-left: 12px;
    }
`

const InstagramLogoFooter = styled(IconOutlinedCustomized)`
    height: 30px;
    width: 30px;

    ${breakpoints.down("sm")} {
        margin-left: 30px;
    }
`

const ConectaBottom = styled(ConectaTitle)`
    margin-top: 35px;

    ${breakpoints.up('sm')} {
        font-size: 30px;
        font-weight: bold;
    }
`

const Copyright = styled.p`
    color: ${colors.neutral.black1};
    font-size: 11px;
    line-height: 15px;

    ${breakpoints.down('sm')} {
        text-align: left;
        font-size: 13px;
        margin-bottom: 9px;
    }
`

const Address = styled(Copyright)`
    text-align: right;
    
    ${breakpoints.down('sm')} {
        text-align: left;
        font-size: 13px;
    }
`

const CopyrightContent = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    
    ${breakpoints.down('sm')} {
        flex-direction: column;
        text-align: left;
        border-top: 1px solid ${colors.neutral.gray03}
    }
`

const SafeContent = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`

const ContainersDesk = styled.div<ContainerItemsDesktop>`
    margin-bottom: ${(props: ContainerItemsDesktop) => `${props.marginBottom}px` ?? 0};
    margin-left: ${(props: ContainerItemsDesktop) => `${props.marginLeft}px` ?? 0};
    margin-right: ${(props: ContainerItemsDesktop) => `${props.marginRight}px` ?? 0};
    margin-top: ${(props: ContainerItemsDesktop) => `${props.marginTop}px` ?? 0};
    margin: ${(props: ContainerItemsDesktop) => `${props.margin}px` ?? 0};
`

const ContainerContentDesktop = styled.div` 
    display: flex;
    padding: 10px 20px 50px;
    box-sizing: border-box;
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1280px;
    width: 100%;
`
const ContainerLogoConecta = styled.div`
    align-self: start;
    align-items: start;
    display: flex;
    flex-direction: column;
    width: 200px;

    ${breakpoints.down("sm")} {
        width: 100%;
    }
`

const ContainerLogoSSL = styled.div`
    align-self: center;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100px;

    ${breakpoints.down("sm")} {
        align-self: start;
        align-items: start;
        justify-content: start;
        width: 100%;
        flex-direction: column;
    }
`

const logoFooter = styled.img`
    max-width: 150px;
    margin: 20px 0;
`

const ImageSSL = styled(logoFooter)`
    max-width: 115px;
    margin-left: 25px;

    ${breakpoints.down("sm")} {
        max-width: 70px;
        margin-left: -5px;
    }
`

const FooterLogoConecta = styled(logoFooter)`
    max-width: 145px;
    margin-left: 25px;

    ${breakpoints.down("sm")} {
        max-width: 170px;
        margin-left: 10px;
    }
`

const SecurityLogo = styled(logoFooter)`
    max-width: 115px;

    ${breakpoints.down("sm")} {
        display: none;
    }
`

const ContainerSafeLogoFooter = styled.div`
    display: flex;
    flex-direction: row;

    ${breakpoints.down("sm")} {
        flex-direction: row;
    }
`

const textFooter = styled.p`
    color: ${colors.neutral.black1};
    font-size: 12px;
    width: 205px;
`
const textFooterLogoConecta = styled.p`
    color: ${colors.neutral.black1};
    font-size: 12px;
    width: 205px;
    margin-left: 22px;

    ${breakpoints.down("sm")} {
        margin-left: 11px;
        font-size: 15px;
        width: 90%;
        max-width: 100%;
    }
`

const SecurityTextFooter = styled(textFooter)`
    color: ${colors.conectaTheme.primary};
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    margin-top: 5px;
    margin-left: 7px;
    text-align: left;

    ${breakpoints.down("sm")} {
        display: none;
    }
`

const SecurityTextFooter2 = styled(textFooter)`
    color: ${colors.neutral.black1};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    width: 321px;

    ${breakpoints.down("sm")} {
        display: none;
    }
`

const SecurityTextFooter3 = styled(textFooter)`
    font-size: 16px;
    color: ${colors.conectaTheme.primary};
    display: block;
    font-weight: 700;
    text-align: left;
    width: 100%;
    margin-left: -3px;
`

const ContainerSecurity = styled.div`
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: column;
`

const Security = styled.div`
    align-items: center;
    align-self: start;
    display: flex;
    flex-direction: row;
`

export const FooterStyled = {
    Address,
    ChannelsTitle,
    ConectaBottom,
    ConectaTitle,
    ConectaTitleMobile,
    ContactsConecta,
    Container,
    ContainerContentDesktop,
    ContainerIcons,
    ContainerItemAndSquare,
    ContainerLinks,
    ContainerLogoConecta,
    ContainerLogoSSL,
    ContainerSafeLogoFooter,
    ContainerSecurity,
    ContainersDesk,
    Copyright,
    CopyrightContent,
    CropSquareCustomized,
    FacebookLogoFooter,
    FacebookOutlinedCustomized,
    FooterLogoConecta,
    IconOutlinedCustomized,
    ImageSSL,
    InstagramLogoFooter,
    LinkItems,
    SafeContent,
    Security,
    SecurityLogo,
    SecurityTextFooter,
    SecurityTextFooter2,
    SecurityTextFooter3,
    logoFooter,
    textFooter,
    textFooterLogoConecta
}