import styled from "styled-components";
import { Select } from "antd";
import { breakpoints } from "design-system/theme";

const CustomSearch = styled(Select)`
  .ant-select-selector {
    ${breakpoints.down("sm")} {
      width: 112% !important;
    }

    ${breakpoints.down("xs")} {
      width: 102% !important;
      max-width: 105% !important;
    }
  }
`;

export const SelectSearchStyled = {
  CustomSearch,
};
