import styled from "styled-components";
import { AccountCircle, Home, Menu } from "@mui/icons-material";
import { colors, breakpoints } from "design-system/theme";
import {
	ContainerProps,
	ItemNavProps,
	NavItemProps,
	ShowMenuProps,
	SubMenuItemsProps,
} from "design-system/domain";

const Container = styled.header<ContainerProps>`
	width: 100%;
	height: 90px;
	background: ${(props: ContainerProps) =>
		props?.backgroundColor ? props?.backgroundColor : colors.primary.gradient};
	display: ${(props: ContainerProps) =>
		props.hideFirstRowHeader ? "none" : "flex"};
	justify-content: space-between;
	align-items: stretch;
	left: 0;
	padding: 8px 75px;
	top: 0;
	position: fixed;
	box-sizing: border-box;
	z-index: 1000;

	${breakpoints.down("lg")} {
		padding: 8px 68px;
	}

	${breakpoints.down("sm")} {
		height: 90px;
		padding: 8px 10px;
	}

	@media (max-width: 1440px) {
		padding: 8px 20px;
	}

	@media (max-width: 1920px) {
		padding: 8px 20px;
	}
`;

const LogoHeader = styled.img`
	max-width: 150px;
	cursor: pointer;
`;

const LogoLink = styled.a`
	display: flex;
	align-items: center;
	font-size: 1.25rem;
	line-height: inherit;
	margin-right: 1rem;
	padding-top: 0.3125rem;
	padding-bottom: 0.3125rem;
	white-space: nowrap;
`;

const AgregaTextContent = styled.a`
	color: ${colors.generics.white};
	font-size: 22px;
	padding: 20px 2px;

	${breakpoints.up("sm")} {
		display: none;
	}
`;

const NavFixed = styled.header<NavItemProps>`
	width: 100%;
	height: ${(props) => (props.showSubItems ? "auto" : "80px")};
	margin-top: ${(props) => (props.hideFirstRowHeader ? "0" : "75px")};
	background: linear-gradient(90deg, #e6072e 0%, #ff002c 0.04%, #f58224 100%);
	display: ${(props) => (props.showSubItems ? "flow-root" : "flex")};
	padding: 25px 60px 8px;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 999;
	box-sizing: border-box;
	${breakpoints.down("sm")} {
		margin-top: ${(props) => (props.hideFirstRowHeader ? "0" : "90px")};
		height: ${(props) => (props.showSubItems ? "auto" : "60px")};
		padding: 8px 20px;
	}
	@media (max-width: 1440px) {
		padding: 25px 10px 8px;
	}

	@media (max-width: 1920px) {
		padding: 25px 10px 8px;
	}
`;

const LinkNav = styled.a`
	color: #fff;
`;

const LogoNav = styled.img`
	border-style: none;
	margin-top: 10px;
	vertical-align: middle;
	width: 100px;
`;

const NavItemsList = styled.ul`
	display: flex;
`;

const ListItem = styled.li<ItemNavProps>`
	color: ${(props) => (props.isActive ? "#fff" : "#fff")};
	cursor: pointer;
	font-size: 16px;
	margin-left: 15px;
	&:hover {
		color: "#fff";
	}
`;

const HomeIconCustomized = styled(Home)`
	cursor: pointer;
	fill: ${colors.neutral.gray05} !important;
	margin-top: -5px;
`;

const SubMenuHeaderContainer = styled.section<ShowMenuProps>`
	width: 300px;
	height: auto;
	max-width: 100%;
	position: absolute;
	right: ${(props) => (props.showMenu ? "0" : "-330px")} !important;
	top: 70px;
	z-index: 1000;
	transition: all 0.4s;
	background-color: ${colors.generics.white};
	border-radius: 30px 0px 0px 30px;
	overflow: hidden;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

	${breakpoints.down("sm")} {
		right: -15px;
		top: 75px;
	}
`;

const UserBoxContainer = styled.section`
	display: inline-block;
	margin-top: 10px;
`;

const FirstChildNavFixed = styled.div`
	display: flex;
	align-items: center;
`;

const SecondChildNavFixed = styled(FirstChildNavFixed)`
	background-color: ${colors.conectaTheme.userComponent};
	cursor: pointer;
	margin-right: 5px;
	border-radius: 10px;
	border: 1px solid ${colors.conectaTheme.userBorderComponent};
	padding: 8px 20px;

	${breakpoints.down("sm")} {
		margin-right: 15px;
	}
`;

const AccountCircleCustomized = styled(AccountCircle)`
	cursor: pointer;
	fill: ${colors.generics.white} !important;
	font-size: 45px !important;
	margin: 0 5px 0 0 !important;
`;

const LoginOrNewAccountLabel = styled.p`
	font-family: "PoppinsMedium";
	font-weight: 600;
	line-height: 1.5em;
	font-size: 12px;
	color: ${colors.neutral.black1};
`;

const LoginLabelText = styled.p`
	font-family: "PoppinsMedium";
	font-weight: normal;
	color: ${colors.neutral.black1};
`;

const ContainerNavBar = styled.div`
	display: flex;
	justify-content: right;
	width: 100%;

	&:hover & ${SubMenuHeaderContainer} {
		display: block;
	}
`;

const ContainerLoginIntems = styled.div`
	margin-top: -7px;
`;
const LoginMobileLabel = styled.a`
	color: ${colors.neutral.gray07};
`;
const MenuHamburgerMobile = styled(Menu)`
	fill: #fff !important;

	${breakpoints.down("sm")} {
		margin-right: 20px;
	}
`;

const NavItemsListMobile = styled(NavItemsList)`
	display: block;
	padding-inline-start: 0;
`;
const ListItemMobile = styled(ListItem)`
	margin-left: 0;
	margin-top: 20px;
`;

const HomeIconCustomizedMobile = styled(HomeIconCustomized)`
	font-size: 23px !important;
	margin-top: 13px;

	${breakpoints.down("sm")} {
		margin-left: 15px;
	}
`;

const SubMenuHeaderUserBalanceContainer = styled.section`
	display: flex;
	border-bottom: 1px solid ${colors.neutral.gray03};
	padding: 25px 0px 15px 25px;
`;

const SubMenuHeaderUserBalance = styled.section`
	padding: 10px;
	width: 90%;
`;

const SubMenuHeaderContainerList = styled.section``;

const SubMenuHeaderUserBalanceContainerText = styled.section`
	display: flex;
	flex-direction: column;
	width: 85%;
`;

const SubMenuHeaderText = styled.p<SubMenuItemsProps>`
	color: ${(props) => props?.colorText ?? colors.neutral.gray03};
	font-size: ${(props) => props?.fontSize ?? "14px"};
	font-weight: ${(props) => props?.textWeight ?? "400"};
	margin: 2px 0;
`;

const SubMenuHeaderList = styled.ul`
	padding: 10px 0px 15px 25px;
	background-color: ${colors.neutral.gray11};
	border-radius: 0px 0px 0px 30px;
`;

const SubMenuHeaderListItem = styled.li`
	margin: 5px 0px;
`;

const SubMenuHeaderListLink = styled.a`
	color: ${colors.neutral.gray03};
	cursor: pointer;
	text-decoration: none;
	font-size: 15px;

	&:hover {
		text-decoration: underline;
		color: ${colors.neutral.gray01};
	}
`;

const SubMenuHeaderRedirectButton = styled.a`
	color: blue;
	width: 10%;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: center;
	background-color: transparent;
	color: ${colors.primary.orange01};
	font-size: 22px;
	cursor: pointer;
`;

const LogoUser = styled.img`
	height: 30px;
	margin-right: 10px;
	width: 24px;
`;

export const HeaderStyled = {
	AccountCircleCustomized,
	AgregaTextContent,
	Container,
	ContainerLoginIntems,
	ContainerNavBar,
	FirstChildNavFixed,
	HomeIconCustomized,
	HomeIconCustomizedMobile,
	LinkNav,
	ListItem,
	ListItemMobile,
	LoginLabelText,
	LoginMobileLabel,
	LoginOrNewAccountLabel,
	LogoHeader,
	LogoLink,
	LogoNav,
	LogoUser,
	MenuHamburgerMobile,
	NavFixed,
	NavItemsList,
	NavItemsListMobile,
	SecondChildNavFixed,
	SubMenuHeaderContainer,
	SubMenuHeaderContainerList,
	SubMenuHeaderList,
	SubMenuHeaderListItem,
	SubMenuHeaderListLink,
	SubMenuHeaderRedirectButton,
	SubMenuHeaderText,
	SubMenuHeaderUserBalance,
	SubMenuHeaderUserBalanceContainer,
	SubMenuHeaderUserBalanceContainerText,
	UserBoxContainer,
};
