import logoConecta from './conecta-gradient.svg'
import SSLLogo from './selo-ssl 2.png'
import safeLogo from './Logo-google-safe-browsing 1.png'
import facebookLogo from './facebookLogo.png'
import instagramLogo from './instagramLogo.png'
import securityLogo from './securityLogo.png'

export const FooterImages = {
    SSLLogo,
    facebookLogo,
    instagramLogo,
    logoConecta,
    safeLogo,
    securityLogo
}