import { breakpoints } from '../../../theme/breakpoints';
import { colors } from "design-system/theme";
import styled from "styled-components";

const InputDate = styled.input`    
    width: 100%;
    max-width: 100%;
    height: 44px;
    padding: 0.6rem 1rem;
    box-sizing: border-box;
    border: ${(props) => props?.border
        ? props?.border
        : `1px solid ${colors.neutral.gray05}`};
    border-radius: 0.25rem;
    color: ${(props) => props?.color
        ? props?.color
        : `${colors.neutral.gray02}`};
    ${breakpoints.down("sm")} {
    }
`;
const Label = styled.label``

const Container = styled.div`
    .react-datepicker{
        border: 0;
        -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.1);
        -moz-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.1);
        box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.1);
        &__navigation{
            &-icon{
                &::after,
                &::before{
                    border-color: ${colors.primary.orange01};
                }
            }
        }
        &__current{
            &-month{
                font-size: 16px;
                text-transform: capitalize;
                color: ${colors.primary.orange01};
            }
        }
        &__day{
            &-name{
                color: ${colors.primary.orange01};
            }
        }
        &__header{
            background-color: ${colors.generics.white};
            border:0 ;
        }
        &__triangle{
            margin-top: -5px;
            margin-bottom: -5px;
            &::after,
            &::before {
                border-top-color: ${colors.generics.white} !important;
                border-bottom-color: ${colors.generics.white} !important;
            }
        }
    }
`
export const CustomInputDate = {
    Container,
    InputDate,
    Label,
}