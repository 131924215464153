import { Http } from "data/protocols/services/api";
import { StorageHelper } from "./storage/storage";
import { HttpMethods } from "data/protocols/http/http-client";

export const logoutUser = async (documentNumber: string): Promise<void> => {
    try {
        const http = new Http<Logout.HttpParams, Logout.Result>
        http.request({
            data: {
                documentNumber: documentNumber
            },
            headers: {
                ["Ocp-Apim-Subscription-Key"]: String(process.env.REACT_APP_AGREGA_SUBSCRIPTION_KEY),
            },
            method: HttpMethods.POST,
            url: `${process.env.REACT_APP_API_CONECTA_AG_URL}/sso/revoke`            
        })
            .then(() => {
                const storageHelper = new StorageHelper();
                storageHelper.clearAll();
                window.location.href = `${process.env.REACT_APP_LOGOUT_CATALOG}`;
            })
    } catch (error) {
        console.error(error)
    }
}

export namespace Logout {
    export type HttpParams = {
        data: {documentNumber: string}

        headers: {
            ["Ocp-Apim-Subscription-Key"]: string;
        };
        method: HttpMethods.POST;
        url: string;
    };
    export type Result = {
        success: boolean
    }
}