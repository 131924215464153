import { breakpoints } from '../../theme/breakpoints';
import { colors } from "design-system/theme";
import styled from "styled-components";

const Container = styled.main`
    background-color: ${colors.generics.grayLight};
`;

const ErrorMessage = styled.p`
    font-size: 12px;
    color: ${colors.status.error};
    padding: 5px;
`;

const Col = styled.div`
    margin-right: 40px;
    ${breakpoints.down("sm")} {
        margin: 0 0 20px 0;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    padding: 50px 0;
    ${breakpoints.down("sm")} {
        flex-direction: column;
    }
`

const CleanSearchFormButton = styled.button`
    background-color: ${(props) => props?.backgroundColor
        ? props?.backgroundColor
        : colors.primary.orange01};
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin-top: 15px;
    color: ${(props) => props?.color
        ? props?.color
        : colors.neutral.backgroundTwo};
    cursor: pointer;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    padding: 0.700rem 0.85rem;
    text-align: center;
    /* margin-top: 15px; */
    transition: 0.3s ease-in;
    vertical-align: middle;

    &:hover {
        background-color: ${(props) => props?.backgroundColor
        ? props?.backgroundColor
        : colors.primary.orange03};
    }

    ${breakpoints.down('sm')} {
        display: block;
        font-size: 20px;
        margin: 0;
        text-align: center;
        width: 100%;
    }
`;

export const Styled = {
    CleanSearchFormButton,
    Col,
    Container,
    ErrorMessage,
    Row,
}