import { FC } from "react";
import { useTitle } from "hooks";
import * as S from "./styled";
import { Link } from "react-router-dom";

const PageElo: FC = () => {
	useTitle("ELO");
	return (
		<S.Main className="roboto margin-elo">
			<S.Banner>
				<div className="container pdd-200">
					<h1>O Programa Elo está de casa nova.</h1>
					<h3>Conheça todas as vantagens do</h3>
					<h2>Conecta Pontos</h2>
					<h3>
						fique tranquilo(a), estamos aqui para te explicar tudo o que está
						acontecendo!
					</h3>
				</div>
			</S.Banner>
			<div className="container back-blue"></div>
			<div className="container pt-10">
				<h4>E o que é o Conecta.ag e o Conecta Pontos?</h4>
				<div className="flex pt-7 mobile-block">
					<div className="block-program flex">
						<div className="block-img">
							<img src="img/block-conecta-ag.png" alt="Conecta-ag" />
						</div>
						<p>
							Um ecossistema que nasceu para te auxiliar em todos os momentos:
							antes, durante e depois da safra. Tudo para tornar o seu negócio
							ainda mais produtivo.
						</p>
					</div>
					<div className="block-program flex">
						<div className="block-img">
							<img src="img/block-conecta-pontos.png" alt="Conecta-pontos" />
						</div>
						<p>
							A plataforma onde você troca pontos por produtos ou serviços para
							o seu negócio e para a sua família.
						</p>
					</div>
				</div>
			</div>

			<div className="flex">
				<S.BackBlue>
					<h1>Já faz parte do programa Elo?</h1>
					<h2>Então os seus pontos já estão no Conecta Pontos.</h2>
					<h2>Veja como acessar:</h2>
				</S.BackBlue>
				<S.BackGuy></S.BackGuy>
			</div>

			<S.BackYellow>
				<div className="yellow-pc">
					<img src="img/pc-mktplace.png" alt="" />
				</div>
				<div className="yellow-text">
					<h2>E agora, como eu acesso?</h2>
					<div className="flex align-center pt-4 gap-20 mobile-block">
						<div className="num">1</div>
						<div className="num-text">Acesse o novo site do programa.</div>
					</div>
					<div className="flex align-center pt-4 gap-20 mobile-block">
						<div className="num">2</div>
						<div className="num-text">
							Na primeira visita, crie uma nova senha clicando em "Esqueci a
							senha".
						</div>
					</div>
					<div className="flex align-center pt-4 gap-20 mobile-block">
						<div className="num">3</div>
						<div className="num-text">
							Faça o login com a nova senha e confira todos os benefícios
							disponíveis.
						</div>
					</div>
					<div className="flex">
						<a
							href="https://conecta.ag/"
							rel="noreferrer"
							target="_blank"
							className="btn-blue"
						>
							Acessar o Conecta Pontos
						</a>
					</div>
				</div>
			</S.BackYellow>
			<S.BackList>
				<div className="container back-blue"></div>
				<div className="container pt-10">
					<h4 className="pb-5">Veja abaixo o que muda no Conecta Pontos</h4>
					<div className="flex space-between mobile-table">
						<div className="old-elo">
							<img src="img/antigo-elo.png" alt="antigo elo" />
							<div className="white-row">
								23 mil opções de produtos e serviços.
							</div>
							<div className="gray-row">Resgate com pontos. </div>
							<div className="white-row">
								Pedido sem acompanhamento no sistema.
							</div>
							<div className="gray-row">Solicitações diretas.</div>
							<div className="white-row">
								Acúmulo de pontos com distribuidores cadastrados no ELO.
							</div>
						</div>

						<div className="old-elo">
							<img
								src="img/novo-conecta-pontos.png"
								alt="novo-conecta-pontos"
							/>
							<div className="white-row bold">
								<i className="fa fa-check-circle yellow-icon"></i> Mais de 270
								mil opções de produtos e serviços.
							</div>
							<div className="gray-row bold">
								<i className="fa fa-check-circle yellow-icon"></i> Duas opções
								de resgate: pontos e pontos + dinheiro.
							</div>
							<div className="white-row bold">
								<i className="fa fa-check-circle yellow-icon"></i> Mais
								agilidade e acompanhamento dos resgates.
							</div>
							<div className="gray-row bold">
								<i className="fa fa-check-circle yellow-icon"></i> Suporte de um
								concierge dedicado.
							</div>
							<div className="white-row bold">
								<i className="fa fa-check-circle yellow-icon"></i> Acúmulo de
								pontos também com outros parceiros (em breve).
							</div>
						</div>
					</div>
				</div>
			</S.BackList>
			<S.BackInfo>
				<div className="container">
					<div className="flex gap-20 mobile-block">
						<div className="info">
							<h2>Ainda com dúvidas?</h2>
							<h3>Canais de atendimento exclusivos para você.</h3>
						</div>
						<div className="info-mail">
							<p className="label">E-mail:</p>
							<br />
							<p className="info-text">elo@basf.com.br</p>
							<br />
							<p className="info-hour">
								De segunda a sexta-feira, das 8h às 19h.
							</p>
						</div>
						<div className="info-mail">
							<p className="label">Telefone:</p>
							<br />
							<p className="info-text">0800 0192 500</p>
						</div>
					</div>
				</div>
			</S.BackInfo>
			<S.ContainerDefault>
				<div className="container">
					<h2>Perguntas frequentes</h2>
					<div className="flex gap-20 mobile-table">
						<div className="question-box">
							<p className="question-title">Como funciona o Conecta Pontos?</p>

							<p className="question-answer">
								Conecta Pontos é o programa de fidelidade do ecossistema
								Conecta.ag. O objetivo do programa é gerar valor para empresas
								parceiras e canais de distribuição. Oferecemos oportunidades no
								dia a dia do produtor para ganhar pontos que podem ser trocados
								por soluções agrícolas, produtos para o seu negócio e família.
							</p>
						</div>

						<div className="question-box">
							<p className="question-title">
								Como faço para participar do Conecta Pontos?
							</p>

							<p className="question-answer">
								Basta se cadastrar no site Conecta.ag. O acesso à plataforma
								está disponível para pessoas físicas e maiores de 18 anos.
							</p>
						</div>

						<div className="question-box">
							<p className="question-title">Como ganhar pontos?</p>

							<p className="question-answer">
								Basta comprar produtos elegíveis nos programas participantes de
								acordo com os respectivos regulamentos.
							</p>
						</div>
					</div>
				</div>
			</S.ContainerDefault>
			<S.ContainerDefault>
				<div className="container">
					<div className="flex gap-20 mobile-table">
						<div className="question-small-box">
							<Link
								to={`${process.env.REACT_APP_CONECTA_AG_URL}/contato`}
								target="_blank"
							>
								<img
									src="img/icon-question-blue.svg"
									className="img-small-box"
								/>

								<p className="text-small-box">
									Precisa falar com a nossa equipe? Acesse nossa{" "}
									<strong>Central de Ajuda</strong>.
								</p>
							</Link>
						</div>

						<div className="question-small-box">
							<Link
								to={`${process.env.REACT_APP_CONECTA_AG_URL}/regulamento-fidelidade`}
								target="_blank"
							>
								<img src="img/icon-tasks-blue.svg" className="img-small-box" />

								<p className="text-small-box">
									Para conferir todas as condições do programa, veja nosso{" "}
									<strong>Regulamento</strong>.
								</p>
							</Link>
						</div>

						<div className="question-small-box">
							<Link
								to={`${process.env.REACT_APP_CONECTA_AG_URL}/faq`}
								target="_blank"
							>
								<img src="img/icon-doubts-blue.svg" className="img-small-box" />

								<p className="text-small-box">
									Para mais informações, confira mais{" "}
									<strong>Perguntas frequentes</strong>.
								</p>
							</Link>
						</div>
					</div>
				</div>
				<div className="pb-10"></div>
			</S.ContainerDefault>
		</S.Main>
	);
};

export default PageElo;
