/* eslint-disable @typescript-eslint/member-ordering */
import { StorageHelper } from "utils/functions/storage/storage";
import { HttpClient, HttpMethods } from "data/protocols/http/http-client";
import { IRegistrationComplement } from "data/protocols/services/account/registration-complement";

export class HttpRegistrationComplement implements IRegistrationComplement {
	constructor(
		private readonly httpClient: HttpClient<
			IRegistrationComplement.Params,
			IRegistrationComplement.HttpResponse
		>,
		private readonly storageHelper: StorageHelper
	) {}

	private headerSubscriptionKeyAndSessionKey = {
		"Access-Control-Allow-Origin": "*",
		Authorization: `Bearer ${this.storageHelper.get("token")}`,
		["Ocp-Apim-Subscription-Key"]: String(
			process.env.REACT_APP_AGREGA_SUBSCRIPTION_KEY
		),
		["X-Session-Id"]: this.storageHelper.get("sessionKey"),
	};

	async complement(params?: IRegistrationComplement.Params): Promise<{
		response: IRegistrationComplement.HttpResponse;
		statusCode: number
		success: boolean;
	}> {
		const result = await this.httpClient.request({
			data: params?.data,
			headers: this.headerSubscriptionKeyAndSessionKey,
			method: HttpMethods.POST,
			url: `${process.env.REACT_APP_BASE_URL_API}participant/participants/optinConectaPontos`,
		});
		return {
			response: result.response,
			statusCode: result.statusCode,
			success: result.success
		};
	}
}
