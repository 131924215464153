import styled from "styled-components";

export const Container = styled.div`
	.container_elo {
		width: 100%;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		background-image: url(/img/background-maintenance.png);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		background-attachment: fixed;
		opacity: 1;
	}

	.content {
		width: 564px;
		height: 336px;
		background: #ffffff 0% 0% no-repeat padding-box;
		border-radius: 24px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		box-shadow: 0px 24px 32px #0000000f;
		border: 1px solid #e8e8e8;
		opacity: 1;
	}

	.title {
		color: #25363f;
		font-size: 24px;
		font-weight: 900;
		font-family: Roboto;
		width: 60%;
		margin-top: 15px;
	}

	.text {
		color: #25363f;
		font-size: 16px;
		font-weight: 400;
		font-family: Roboto;
		width: 80%;
		margin-top: 15px;
	}

	.small {
		color: #25363f;
		font-size: 14px;
		font-weight: 700;
		font-family: Roboto;
		width: 80%;
		margin-top: 15px;
	}

	@media screen and (max-width: 600px) {
		.content {
			width: 80%;
			height: 50%;
			padding: 30px;
		}
	}

	@media screen and (max-width: 400px) {
		.content {
			width: 80%;
			height: 60%;
			padding: 30px;
		}
	}
`;

export const MaintenancePageStyled = {
	Container,
};
