import { FC } from "react";
import { ButtonStyled } from "./styled";
import { ButtonProps } from "design-system/domain/components";

const Button: FC<ButtonProps> = ({
  buttonText,
  disabled,
  className,
  height,
  padding,
  width,
  eventClick,
}) => {
  return (
    <>
      {eventClick ? (
        <ButtonStyled.ButtonCustomized
          width={width}
          height={height}
          padding={padding}
          className={className}
          disabled={disabled}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            eventClick(e)
          }
        >
          {buttonText}
        </ButtonStyled.ButtonCustomized>
      ) : (
        <ButtonStyled.ButtonCustomized
          width={width}
          height={height}
          padding={padding}
          className={className}
          disabled={disabled}
        >
          {buttonText}
        </ButtonStyled.ButtonCustomized>
      )}
    </>
  );
};

export default Button;
