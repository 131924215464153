export class DateHelper {
  /**
   * @description
   * Convert YYYY-MM-DD to DD-MM-YYYY
   */
  convertAmericanFormatToBrazilFormat(date: string, separator?: "-" | "/"): string {
    const [year, month, day] = date.split("-");
    return `${day}${separator ? separator : '-'}${month}${separator ? separator : '-'}${year}`;
  }
}
