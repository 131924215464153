/* eslint-disable sort-keys */
import { useSelector } from "react-redux";
import { Http } from "data/protocols/services/api";
import { useEffect, useState } from "react";
import { RootState } from "store";
import { useTitle } from "hooks";
import { Styled } from "./style";
import { TitlePage, TableList, ContainerPage } from "design-system/components";
import { StorageHelper } from "utils/functions/storage/storage";
import { HttpLegacyExtract } from "services/extract/legacy-extract";
import { ILoadLegacyExtract } from "data/protocols/services/extract/legacy-extract";
import { DateHelper } from "utils/functions/date/date";
import { useNavigate } from "react-router-dom";

const LegacyExtractPage = () => {
	const [datesFilter, setDatesFilter] = useState({
		startDate: "",
		endDate: "",
	});
	const [extractData, setExtractData] =
		useState<ILoadLegacyExtract.RowsAndTotal>({
			rows: [],
			total: 0,
		});
	const { user } = useSelector((state: RootState) => state);
	const navigate = useNavigate();

	useTitle(`Extrato legado`);

	const httpLegacyExtract = new HttpLegacyExtract(
		new Http(),
		new StorageHelper()
	);
	const dateHelper = new DateHelper();

	const fetchExtract = async () => {
		const result = await httpLegacyExtract.getExtract({
			data: {
				documentNumber: user.document_number as string,
				endDate: dateHelper.convertAmericanFormatToBrazilFormat(
					datesFilter.endDate
				),
				startDate: dateHelper.convertAmericanFormatToBrazilFormat(
					datesFilter.startDate
				),
			},
		});
		setExtractData({
			rows: result.rows,
			total: result.total,
		});
	};

	useEffect(() => {
		if (!user.is_logged) {
			navigate("/programas-parceiros");
		}
	}, [user]);

	useEffect(() => {
		if (!extractData.rows.length) {
			fetchExtract();
		}
	}, []);
	return (
		<>
			<ContainerPage>
				<TitlePage
					title={`Extrato anterior ao dia ${process.env.REACT_APP_LEGACYDATE}`}
				/>

				<Styled.ContainerSubTitleText>
					<Styled.SubTitleText>
						Consulte aqui o histórico de transções realizadas antes do dia{" "}
						{process.env.REACT_APP_LEGACYDATE}
					</Styled.SubTitleText>
				</Styled.ContainerSubTitleText>

				<Styled.RowInputs>
					<Styled.InputDates
						value={datesFilter.startDate}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setDatesFilter({
								...datesFilter,
								startDate: e.target.value,
							})
						}
						type="date"
					/>
					<Styled.InputDates
						value={datesFilter.endDate}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setDatesFilter({
								...datesFilter,
								endDate: e.target.value,
							})
						}
						type="date"
					/>
					<Styled.SearchExtractButton onClick={() => fetchExtract()}>
						Pesquisar
					</Styled.SearchExtractButton>
				</Styled.RowInputs>

				<TableList
					data={{
						columnTitles: [
							"Cash",
							"Pontos",
							"Data de transação",
							"Tipo de transação",
						],
						rows: extractData.rows.map(
							(extract: ILoadLegacyExtract.ExtractRow) => ({
								["Cash"]: extract.cash.toString(),
								["Pontos"]: extract.points.toString(),
								["Data de transação"]: extract.transactionDate.toString(),
								["Tipo de transação"]: extract.transactionType.toString(),
							})
						),
						totalItems: extractData.total,
					}}
				/>
			</ContainerPage>
		</>
	);
};

export default LegacyExtractPage;
