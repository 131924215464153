/* eslint-disable sort-keys */

import { breakpoints } from "../../design-system/theme/breakpoints";
import styled from "styled-components";

const ContainerPage = styled.div`
  ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: transparent;
  }

  * {
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  *:focus {
    outline: none;
  }

  #app-cover {
    display: table;
    width: 600px;
    margin: 80px auto;
    counter-reset: button-counter;
  }

  .row {
    display: table-row;
  }

  .toggle-button-cover {
    display: table-cell;
    position: relative;
    width: 235px;
    height: 140px;
    box-sizing: border-box;

    ${breakpoints.up("lg")} {
      width: 200px;
    }
  }

  .button-cover {
    height: 100px;
    margin: 20px;
    background-color: transparent;
    border-radius: 4px;
  }

  .button-cover:before {
    counter-increment: button-counter;
    content: counter(button-counter);
    position: absolute;
    right: 0;
    bottom: 0;
    color: #d7e3e3;
    font-size: 0px;
    line-height: 1;
    padding: 5px;
  }

  .button-cover,
  .knobs,
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .button {
    position: relative;
    top: 50%;
    left: -7%;
    width: 74px;
    height: 45px;
    margin: -14px auto 0 auto;
  }

  .button.r,
  .button.r .layer {
    border-radius: 100px;
  }

  .button.b2 {
    border-radius: 2px;
    width: 135%;
  }

  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }

  .knobs {
    z-index: 2;
  }

  .layer {
    background-color: transparent;
    border: 1px solid #c5c0c0;
    border-radius: 40px;
    transition: 0.3s ease all;
    width: 100%;
    z-index: 1;
  }

  /* Button 10 */
  #button-10 .knobs:before,
  #button-10 .knobs:after,
  #button-10 .knobs span {
    position: absolute;
    top: 3px;
    width: 86px;
    height: 10px;
    font-size: 10px;
    text-align: center;
    line-height: 8px;
    padding: 9px 4px;
    border-radius: 2px;
    transition: 0.3s ease all;
  }

  #button-10 .knobs:before {
    content: "";
    left: 3px;
    background-color: #fc3229;
    border-radius: 25px;
    height: 21px;
    width: 110px;

    ${breakpoints.up("lg")} {
      width: 90px;
    }
  }

  #button-10 .knobs:after {
    content: "Não vigente";
    color: #4e4e4e;
    font-size: 14px;
    right: 16px;
    top: 27%;

    ${breakpoints.up("lg")} {
      font-size: 12px;
      right: 5px;
      margin-top: -2px;
    }
  }

  #button-10 .knobs span {
    color: #fff;
    display: inline-block;
    font-size: 14px;
    left: 17px;
    top: 23%;
    z-index: 1;

    ${breakpoints.up("lg")} {
      font-size: 12px;
      left: 6px;
    }
  }

  #button-10 .checkbox:checked + .knobs span {
    color: #4e4e4e;
  }

  #button-10 .checkbox:checked + .knobs:before {
    background-color: rgb(244, 67, 54);
    border-radius: 25px;
    left: 141px;
    height: 21px;

    ${breakpoints.up("lg")} {
      left: 114px;
    }
  }

  #button-10 .checkbox:checked + .knobs:after {
    color: #fff;
  }

  #button-10 .checkbox:checked ~ .layer {
    background-color: transparent;
    border: 1px solid #c5c0c0;
    border-radius: 40px;
  }
`;
const TitlePage = styled.section`
  font-family: "PoppinsBold", sans-serif;
  color: #282525;
  font-size: 32px;
  line-height: 34px;
`;

const TitleCampaignBox = styled.div`
  margin: 50px 1px 35px 25px;
`;

const DescriptionBox = styled.div`
  height: 125px;
  margin: 15px 15px 15px 25px;
  overflow: auto;
`;

const DateCampaignBox = styled.div`
  display: flex;
  margin: 50px 1px 35px 25px;

  p {
    margin-left: 10px;
  }

  svg {
    fill: orange !important;
  }
`;

const CampaignsListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 30px;
  width: 100%;
`;

const BoxCampaign = styled.div`
  border: 1px solid #e3d4d4;
  border-radius: 0 0 24px 30px;
  width: 319px;
  margin-bottom: 90px;

  @media (max-width: 500px) {
    display: block;
    margin: auto;
  }

  .button-rescue-now {
	  font-family: "PoppinsBold", sans-serif;
    background: #fc3229;
    border: 2px solid #fc3229;
    border-radius: 30px;
    color: #fefefe;
    height: 50px;
    outline: 0;
    width: 90%;

    cursor: pointer;

    &:hover {
      opacity: 0.7;
      transition: 0.2s;
    }
  }

  .button-how-work {
	font-family: "PoppinsBold", sans-serif;
    background: transparent;
    border: 2px solid #fc3229;
    border-radius: 30px;
    color: #fc3229;
    height: 50px;
    outline: 0;
    width: 90%;

    cursor: pointer;

	&:hover {
		opacity: 0.7;
		transition: 0.2s;
	}
  }

  .img-campaign {
    width: 100%;
  }
`;

const ButtonShowMore = styled.button`
  font-family: "PoppinsBold", sans-serif;
  background: #fc3229;
  border: none;
  border-radius: 37px;
  color: #fff;
  font-size: 18px;
  height: 45px;
  width: 400px;

  cursor: pointer;

  @media (max-width: 500px) {
    width: 90%;
    margin: auto;
  }
`;

const RowCenter = styled.div`
  text-align: center;
`;

const RowCenterWithBottomMargin = styled(RowCenter)`
  margin-bottom: 20px;
`;

const RowForm = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 7px;
  padding: 10px 0 5px;
  gap: 5px;

  @media (max-width: 1024px) {
    flex-direction: column;
    flex-grow: 1;
    align-content: center;
    margin: auto;
    text-align: center;
    flex-wrap: wrap;
  }

  .box-form-field {
    label {
      margin-left: 20px;
      position: relative;
      top: -10px;
      font-size: 13px;

      @media (max-width: 1024px) {
        top: 5px;
        margin-bottom: 10px;
        text-align: left;
      }
    }

    display: grid;

    .field-row-form {
      border-radius: 45px;
      height: 45px;
      padding-left: 20px;
      width: 275px;

      @media (max-width: 1200px) {
        width: 220px;
      }

      @media (max-width: 500px) {
        margin-bottom: 10px;
      }

      ${breakpoints.up("lg")} {
        width: 235px;
      }
    }
  }

  button {
    cursor: pointer;
    border: none;
    border-radius: 40px;
    height: 45px;
    margin-top: 20px;
    outline: 0;
    width: 140px;

    @media (max-width: 1200px) {
      margin-top: 35px;
    }

    @media (max-width: 1024px) {
      margin-top: 10px;
      width: auto;
    }

    ${breakpoints.up("lg")} {
      width: 140px;
    }
  }

  #btn-apply-filter {
    background: #fc3229;
    color: #fff;
  }

  #btn-clear-form {
    background: transparent;
    border: 1px solid #fc3229;
    color: #fc3229;

    @media (max-width: 1200px) {
      margin-bottom: 20px;
    }
  }

  ${breakpoints.up("lg")} {
    display: flex;
  }
`;

const Figure = styled.figure<{ optinProgram: boolean; optinStatus: boolean }>`
  display: inline-block;
  position: relative;
  width: 100%;

  figcaption {
    font-size: 40px;
    position: absolute;
    top: 15px;
  }

  div {
    background: ${(props) => {
      if (props.optinStatus && props.optinProgram) {
        return "#00a64e"; // green
      }

      if (!props.optinStatus && props.optinProgram) {
        return "#f3c028eb"; // yellow
      }

      return "#fc3229"; // red
    }};
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    font-size: 14px;
    padding: 9px;

    p {
      color: #fff;
    }
  }
`;
const MessageWithoutResult = styled.div`
  padding-bottom: 10px;
  text-align: center;
`;

const GrayBoxForm = styled.div`
  background: #f7f7f7;
  display: flex;
  padding: 15px 0;
`;

const BannerActions = styled.button<{ $isLogged: boolean; $optinProgram: boolean }>`
  background: transparent;
  border: none;

  cursor: ${(props) => (props.$isLogged && props.$optinProgram ? "pointer" : "default")};
`;

export const ProgramPageStyled = {
  BannerActions,
  BoxCampaign,
  ButtonShowMore,
  CampaignsListContainer,
  ContainerPage,
  DateCampaignBox,
  DescriptionBox,
  Figure,
  GrayBoxForm,
  MessageWithoutResult,
  RowCenter,
  RowCenterWithBottomMargin,
  RowForm,
  TitleCampaignBox,
  TitlePage,
};
