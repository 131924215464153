const unit = "px";
const sizes: {[key: string]: { end?: number, start: number}} = {
lg: {
    end: 1919,
    start: 1280
},
md: {
    end: 1279,
    start: 960,
},
sm: {

    end: 959,
    start: 414,
},
xl: {
    start: 1920
},
xs: {
    end: 413,
    start: 0
  }
}

type options = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const up = (size: options | number) => {
  let value;

  if (typeof size === "number") {
    value = size
  } else {
    value = sizes[size].start
  }

  return `@media (min-width:${value}${unit})`;
}

const down = (size: options | number) => {
  let value;

  if (typeof size === "number") {
    value = size
  } else {
    value = sizes[size].end
  }

  return `@media (max-width:${value}${unit})`;
}

const between = (startSize: options | number, endSize: options | number) => {
  let startValue, endValue;

  if (typeof startSize === "number") {
    startValue = startSize
  } else {
    startValue = sizes[startSize].start
  }

  if (typeof startSize === "number") {
    endValue = endSize
  } else {
    endValue = sizes[endSize].end
  }

  return `@media (max-width:${endValue}${unit}) and (min-width:${startValue}${unit})`;
}

const only = (key: options | number) => {
  return between(key, key);
}

export const breakpoints = {
  between,
  down,
  only,
  sizes,
  up
}
