import styled from "styled-components";
import { colors } from "design-system/theme";
import { InputStyledProps } from "design-system/domain/components";

const ButtonCustomized = styled.button<InputStyledProps>`
    background: ${(props: InputStyledProps) => props?.backgroundColor ? props?.backgroundColor : `${colors.primary.orange01}`};
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: ${(props: InputStyledProps) => props?.color ? props?.color : `${colors.neutral.backgroundTwo}`};
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    height: ${(props: InputStyledProps) => props?.height ?? props?.height};
    line-height: 1.5;
    padding: ${(props: InputStyledProps) => props?.padding ?? '0.700rem 0.85rem'};
    text-align: center;
    transition: 0.3s ease-in;
    vertical-align: middle;
    width: ${(props: InputStyledProps) => props?.width ?? props?.width};

    
    &:hover {
        background-color: ${(props: InputStyledProps) => props?.backgroundColor ? props?.backgroundColor : `${colors.primary.orange02}`};
    }

    &:disabled {
        background-color: ${colors.generics.grayLight};
        color: ${colors.neutral.gray01}
    }
`;

export const ButtonStyled = {
    ButtonCustomized
}