import { colors } from "design-system/theme";
import { MosaicStyledProps } from "design-system/domain/components";
import styled from "styled-components";

const MosaicContainer = styled.section`
    display: block;
    height: 100%;
    max-width: 100%;
    max-height: 1000px;
    margin: 80px auto;
    padding: 0 auto;    
    width: 100%;
`;

const MosaicRow = styled.div`
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    max-width: 100%;
    width: 100%;
`;

const MosaicImg = styled.img<MosaicStyledProps>`
    border: ${(props: MosaicStyledProps) => props?.border ? props?.border : "none"};
    display: block;
    height: ${(props: MosaicStyledProps) => props?.height ? props?.height : "100%"};
    margin-left: 12px;
    margin-bottom: ${(props: MosaicStyledProps) => props?.marginBottom ? props?.marginBottom : "10px"};
    margin-top: ${(props: MosaicStyledProps) => props?.marginBottom ? props?.marginBottom : "-5px"};
    max-height: ${(props: MosaicStyledProps) => props?.maxHeight ? props?.maxHeight : "100%"};
    max-width: ${(props: MosaicStyledProps) => props?.maxWidth ? props?.maxWidth : "100%"};
    width: ${(props: MosaicStyledProps) => props?.width ? props?.width : "100%"};
`;

const MosaicBlock = styled.div`
    background-color: ${colors.primary.orange02};
    border: ${(props: MosaicStyledProps) => props?.border ? props?.border : "none"};
    display: block;
    height: ${(props: MosaicStyledProps) => props?.height ? props?.height : "100%"};
    margin-left: 12px;
    margin-bottom: 25px;
    max-height: ${(props: MosaicStyledProps) => props?.maxHeight ? props?.maxHeight : "100%"};
    max-width: ${(props: MosaicStyledProps) => props?.maxWidth ? props?.maxWidth : "100%"};
    width: ${(props: MosaicStyledProps) => props?.width ? props?.width : "100%"};
`;

const MosaicBlockContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MosaicStyled = {
    MosaicBlock,
    MosaicBlockContainer,
    MosaicContainer,
    MosaicImg,
    MosaicRow
}