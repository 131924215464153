import { FC } from "react";
import { TitlePageStyled } from "./styled";
import { TitlePageProps } from "design-system/domain";

const TitlePage: FC<TitlePageProps> = ({ title, id }) => (
  <TitlePageStyled.Container>
    <TitlePageStyled.Row>
      <TitlePageStyled.Col12>
        <TitlePageStyled.TitleContent id={id}>
          {title}
        </TitlePageStyled.TitleContent>
      </TitlePageStyled.Col12>
    </TitlePageStyled.Row>
  </TitlePageStyled.Container>
);

export default TitlePage;
