/* eslint-disable sort-keys */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserState } from "store/domain";

const INITIAL_STATE: UserState = {
	aud: null,
	document_number: null,
	email: null,
	exp: null,
	iat: null,
	is_logged: false,
	iss: null,
	name: null,
	nbf: null,
	opt_in_points: null,
	opt_in_terms_and_privacy: null,
	opt_in_comunication: null,
	points: null,
	promotion_code: null,
	session_key: null,
	token: null,
};

const sliceUser = createSlice({
	initialState: INITIAL_STATE,
	name: "user",
	reducers: {
		populateUser(_state, { payload }: PayloadAction<UserState>) {
			return {
				...payload,
			};
		},
		setOptiInsAsTrue(state, { payload }: PayloadAction<boolean>) {
			return {
				...state,
				opt_in_points: true,
				opt_in_terms_and_privacy: true,
				opt_in_comunication: payload
			};
		},
		setOptin(state, { payload }: PayloadAction<boolean>) {
			return {
				...state,
				agrega_opt_in: payload,
			};
		},

		setScore(state, { payload }: PayloadAction<number>) {
			return {
				...state,
				points: payload,
			};
		},
	},
});

export default sliceUser.reducer;

export const { populateUser, setOptin, setOptiInsAsTrue, setScore } =
	sliceUser.actions;

export const useUser = (state: { [key: string]: unknown }) => {
	return state.user as UserState;
};
