import { Http } from "../../../data/protocols/services/api";
import { HttpRegistrationRedirect } from "../../../services/account-user/redirect";
import { StorageHelper } from "../../../utils/functions/storage/storage";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setCurrentCampaign } from "store/slices/campaign";
import { Button } from "./styled";
import { useNavigate } from 'react-router-dom';

enum BannerLinkType {
  NoneLink = "NoneLink",
  RedirectToCatalog = "RedirectToCatalog",
  CustomLink = "CustomLink",
}

interface ButtonsProps {
  campaign: {
    bannerCustomLink: string;
    bannerLinkType: BannerLinkType | 0;
    customUrl: string | null;
    descriptiveCampaign: string;
    endDate: Date;
    id: string;
    imgCampaign: string;
    isExpired: boolean;
    name: string;
    optinProgram: boolean;
    optinProgramStatus: boolean;
    programId: string;
    startDate: Date;
    visibleForAll: boolean;
  };
  user: {
    document_number: string | null;
    email: string | null;
  };
}

export const Buttons = (props: ButtonsProps) => {
  const { campaign, user } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const http = new Http();
  const storageHelper = new StorageHelper();
  const httpRegistrationRedirect = new HttpRegistrationRedirect(
    http,
    storageHelper
  );

  const handleRedirectToCatalog = async () => {
    try {
      const log = await httpRegistrationRedirect.getUrlRedirect({
        data: {
          documentNumber: user.document_number as string,
          email: user.email as string,
        },
      });
      localStorage.setItem("user_redirect", "isRedirect");
      window.location.href = log.response.data;
    } catch (error: unknown) {
      toast.error("Não foi possível redirecionar para o catálogo.", {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "error_redirect_catalog",
      });
    }
  };

  const handleRedirectToCustomLink = () => {
    const customLink = campaign.bannerCustomLink;

    if (customLink.includes("http")) {
      return window.open(customLink, "_blank", "noopener,noreferrer");
    }
    return window.open(`http://${customLink}`, "_blank", "noopener,noreferrer");
  };

  const handleHowItWorks = () => {
    if (!campaign.customUrl) {
      dispatch(
        setCurrentCampaign({
          id: campaign.id,
          optinProgram: campaign.optinProgram,
          optinStatus: campaign.optinProgramStatus,
        })
      )
      navigate(`/programa/${campaign.programId}/${campaign.id}`);
    } else {
      window.open(campaign.customUrl, "_self", "noopener,noreferrer");
    }
  }

  return (
    <>
      {campaign.optinProgram &&
        campaign.bannerLinkType === BannerLinkType["RedirectToCatalog"] && (
          <Button
            $typeButton="default"
            onClick={handleRedirectToCatalog}
          >
            Resgate Agora
          </Button>
        )}
      {campaign.optinProgram &&
        campaign.bannerLinkType === BannerLinkType["CustomLink"] && (
          <Button
            $typeButton="default"
            onClick={handleRedirectToCustomLink}
          >
            Acumule Pontos
          </Button>
        )}
      <Button
        $typeButton="outline"
        onClick={handleHowItWorks}
      >
        Como funciona
      </Button>
    </>
  );
};
