import styled from "styled-components";
import { CircularLoading } from "design-system/components";

const Container = styled.div`
  height: 300px;
  text-align: center;
  padding-top: 50px;
`;

const BoxLoading = styled.div`
  display: grid;
  justify-content: center;
  margin-bottom: 25px;
`;

const LabelCheckTokenStatus = styled.p`
  font-size: 20px;
  font-weight: 700;
`;

export const LogoutPageStyled = {
  BoxLoading,
  CircularLoading,
  Container,
  LabelCheckTokenStatus
}