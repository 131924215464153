import styled from "styled-components";

interface ButtonStyledProps {
    $typeButton: 'default' | 'outline'
}

export const Button = styled.button<ButtonStyledProps>`
    font-family: "PoppinsBold", sans-serif;
    background-color: ${({ $typeButton }) => $typeButton === 'outline' ? 'transparent' : '#fc3229'};
    border: 2px solid #fc3229;
    border-radius: 30px;
    color: ${({ $typeButton }) => $typeButton === 'outline' ? '#fc3229' : '#fefefe'};
    height: 50px;
    outline: 0;
    width: 90%;
    margin-bottom: 0.75rem;

    cursor: pointer;

    &:hover {
      transition: 0.2s;
      letter-spacing: 1px;
    }
`