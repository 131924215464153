import styled from "styled-components";
import { Button } from "design-system/components";
import { colors } from "design-system/theme/colors";

const CloseButtom = styled.a`
	position: absolute;
	right: 20px;
	top: -5px;
	font-size: 30px;
	cursor: pointer;
`;

const Title = styled.h2`
	color: #fd132b;
`;
const SubTitleSite = styled.span`
	color: #fd132b;
	font-weight: 800;
	text-decoration: underline;
`;

const HeaderRow = styled.div`
	background: ${colors.generics.grayLight};
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
	padding: 15px 25px;
`;

const LogoHeaderModal = styled.img`
	height: 20px;
`;

const LinksSteps = styled.a`
	color: #fd132b;
	text-decoration: underline;
`;

const ModalFooter = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 20px;
`;

const CustomCancelButton = styled(Button)`
	background-color: ${colors.generics.grayLight};
	color: ${colors.generics.darkBlue};

	&:hover {
		color: ${colors.generics.white};
	}
`;

const CustomConfirmButton = styled(Button)`
	background-color: #fd132b;
	border-radius: 20px;
	width: 200px;

	&:hover {
		background-color: #ff2e44;
	}
`;

const ModalBody = styled.div`
	display: block;
	margin-top: 20px;
	padding: 10px 25px;
`;

const RowButton = styled.div`
	margin-top: 35px;
	text-align: center;
	margin-bottom: 25px;
`;

const ListInstructions = styled.ul`
	list-style-type: disc;
	margin-bottom: 10px;
	padding: 0 30px;
`;

export const UserNotLoggedStyled = {
	CloseButtom,
	CustomCancelButton,
	CustomConfirmButton,
	HeaderRow,
	LinksSteps,
	ListInstructions,
	LogoHeaderModal,
	ModalBody,
	ModalFooter,
	RowButton,
	SubTitleSite,
	Title,
};
