import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Campaign } from 'store/domain'


const INITIAL_STATE: Campaign = {
    id: null,
    optinProgram: false,
    optinStatus: false
}


const sliceCampaign = createSlice({
    initialState: INITIAL_STATE,
    name: 'campaign',
    reducers: {
        setCurrentCampaign(_state, { payload }: PayloadAction<Campaign>) {
            return { ...payload }
        }
    }
})

export default  sliceCampaign.reducer

export const { 
    setCurrentCampaign
} = sliceCampaign.actions

export const useCampaign = (state: {[key: string]: unknown}) => {
    return state.campaign as Campaign
}