import { Http } from "../data/protocols/services/api";
import { StorageHelper } from "../utils/functions/storage/storage";
import { HttpRegistrationRedirect } from "../services/account-user/redirect";
import { toast } from "react-toastify";
import { UserState } from "../store/domain";

interface BannerLinkProps {
  bannerCustomLink: string;
  bannerLinkType: "NoneLink" | "RedirectToCatalog" | "CustomLink";
  optinProgram: boolean;
  user: UserState;
}

export const useBannerActions = async (props: BannerLinkProps) => {
  const http = new Http();
  const storageHelper = new StorageHelper();
  const httpRegistrationRedirect = new HttpRegistrationRedirect(
    http,
    storageHelper
  );

  if (props.user.is_logged && props.optinProgram) {
    if (props.bannerLinkType === "CustomLink") {
      const customLink = props.bannerCustomLink;

      if (customLink.includes("http")) {
        return window.open(customLink, "_blank", "noopener,noreferrer");
      }
      return window.open(`http://${customLink}`, "_blank", "noopener,noreferrer");

    } else if (props.bannerLinkType === "RedirectToCatalog") {

      try {
        const log = await httpRegistrationRedirect.getUrlRedirect({
          data: {
            documentNumber: props.user.document_number as string,
            email: props.user.email as string,
          },
        });

        localStorage.setItem("user_redirect", "isRedirect");
        window.location.href = log.response.data;

      } catch (error: unknown) {
        toast.error("Qualquer problema redirecionando para o catálogo.", {
          position: toast.POSITION.TOP_RIGHT,
          toastId: "error_redirect_catalog",
        });
      }

    }
  }
};
