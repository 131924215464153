import { Modal } from "design-system/components";

import { Content } from "./Content";
import { Header } from "./Header";
import { ModalUserIsNotLoggedProps } from "./types";
import { useEffect, useState } from "react";

const screenWidth = window.innerWidth;
const styles = {
	borderRadius: 20,
	height: screenWidth >= 1024 ? 360 : 494,
	padding: 0,
	width: screenWidth >= 1024 ? 916 : 311,
}

const ModalUserIsNotLogged = ({
	handleCloseModal,
	isOpen,
}: ModalUserIsNotLoggedProps) => {
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		setLoading(false);
	}, [loading]);

	return (
		<>
			{
				!loading && (
		
					<Modal
						style={styles}
						header={<Header handleCloseModal={() => handleCloseModal()} />}
						content={<Content />}
						titleAlign="center"
						open={isOpen as boolean}
						size="MEDIUM"
					/>
				)
			}
		</>
	)
}

export default ModalUserIsNotLogged;
