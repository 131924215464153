import { FC } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NotificationsProps } from "design-system/domain/components";

const Notifications: FC<NotificationsProps> = ({ customId,  message }) => {
  const toastId = customId ?  customId :  "custom-id";

  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    toastId: toastId,
  });

  return <ToastContainer autoClose={5000} />;
};

export default Notifications;
