import { FC, Fragment } from "react";
import { isMobile } from "react-device-detect";
import {
	FooterLinks,
	FooterProps,
	SocialMediaLinks,
} from "design-system/domain";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FooterStyled } from "./styled";
import { FooterImages } from "design-system/images";
import { Accordion } from "design-system/components";
import { colors } from "design-system/theme";
import { blockRoutesToShowElement } from "utils/consts/blocked-routes-show-header-and-footer";

const footerLinks: FooterLinks = {
	account: [
		{
			link: `${process.env.REACT_APP_CONECTA_AG_URL}/customer/account/`,
			title: "Meus dados",
		},
		{
			link: `${process.env.REACT_APP_CONECTA_AG_URL}/sales/order/history`,
			title: "Meus pedidos",
		},
		{
			link: `${process.env.REACT_APP_CONECTA_AG_URL}/wishlist`,
			title: "Favoritos",
		},
		{
			link: `${process.env.REACT_APP_CONECTA_AG_URL}/catalog/product_compare`,
			title: "Comparar Produtos",
		},
	],
	basfContent: [
		{
			link: `${process.env.REACT_APP_CONECTA_AG_URL}/faq`,
			title: "Ajuda",
		},
		{
			link: `${process.env.REACT_APP_CONECTA_AG_URL}/quem-somos`,
			title: "Sobre o Conecta",
		},
		{
			link: `${process.env.REACT_APP_CONECTA_AG_URL}/contato`,
			title: "Contato",
		},
	],
	overview: [
		{
			link: `${process.env.REACT_APP_CONECTA_AG_URL}/politica-de-privacidade`,
			title: "Política de privacidade",
		},
		{
			link: `${process.env.REACT_APP_CONECTA_AG_URL}/termos-de-uso`,
			title: "Termos de uso",
		},
	],
	products: [
		{
			link: `${process.env.REACT_APP_CONECTA_AG_URL}/todos-os-produtos`,
			title: "Todos os produtos",
		},
		{
			link: `${process.env.REACT_APP_CONECTA_AG_URL}/catalogsearch/advanced`,
			title: "Pesquisa Avançada",
		},
	],
};

const socialMediaLinks: SocialMediaLinks = {
	facebook: "https://www.facebook.com/plataforma.conecta/",
	instagram: "https://www.instagram.com/conecta_ag/",
};

const Footer: FC<FooterProps> = ({ className }) => {
	const conectaLogo = (): JSX.Element => (
		<>
			<FooterStyled.ContainerLogoConecta>
				<FooterStyled.FooterLogoConecta src={FooterImages.logoConecta} />
				<FooterStyled.textFooterLogoConecta>
					O Conecta traz uma nova forma de se relacionar e de estar conectado
					com as diversas possiblidades de negócios que o Agronegócio e Mercado
					de Controle de Pragas oferecem.
				</FooterStyled.textFooterLogoConecta>
			</FooterStyled.ContainerLogoConecta>
		</>
	);

	const basfContent = (): JSX.Element => (
		<>
			<FooterStyled.ContainerLinks>
				{footerLinks.basfContent.map((item, key: number) => (
					<FooterStyled.ContainerItemAndSquare key={key}>
						<FooterStyled.LinkItems href={item.link} target="_blank">
							{item.title}
						</FooterStyled.LinkItems>
					</FooterStyled.ContainerItemAndSquare>
				))}
			</FooterStyled.ContainerLinks>
		</>
	);

	const products = (): JSX.Element => (
		<>
			<FooterStyled.ContainerLinks>
				{footerLinks.products.map((item, key: number) => (
					<FooterStyled.ContainerItemAndSquare key={key}>
						<FooterStyled.LinkItems href={item.link} target="_blank">
							{item.title}
						</FooterStyled.LinkItems>
					</FooterStyled.ContainerItemAndSquare>
				))}
			</FooterStyled.ContainerLinks>
		</>
	);

	const overview = (): JSX.Element => (
		<>
			<FooterStyled.ContainerLinks>
				{footerLinks.overview.map((item, key: number) => (
					<FooterStyled.ContainerItemAndSquare key={key}>
						<FooterStyled.LinkItems href={item.link} target="_blank">
							{item.title}
						</FooterStyled.LinkItems>
					</FooterStyled.ContainerItemAndSquare>
				))}
			</FooterStyled.ContainerLinks>
		</>
	);

	const account = (): JSX.Element => (
		<>
			<FooterStyled.ContainerLinks>
				{footerLinks.account.map((item, key: number) => (
					<FooterStyled.ContainerItemAndSquare key={key}>
						<FooterStyled.LinkItems href={item.link} target="_blank">
							{item.title}
						</FooterStyled.LinkItems>
					</FooterStyled.ContainerItemAndSquare>
				))}
			</FooterStyled.ContainerLinks>
		</>
	);

	const socialMedia = (): JSX.Element => (
		<>
			<FooterStyled.ContainerIcons className="flex">
				<a href={socialMediaLinks.facebook} rel="noreferrer" target="_blank">
					<FooterStyled.FacebookLogoFooter
						src={FooterImages.facebookLogo}
						alt="Logo Facebook"
					/>
				</a>
				<a href={socialMediaLinks.instagram} rel="noreferrer" target="_blank">
					<FooterStyled.InstagramLogoFooter
						src={FooterImages.instagramLogo}
						alt="Logo Instagram"
					/>
				</a>
			</FooterStyled.ContainerIcons>
		</>
	);

	const safeContent = (): JSX.Element => (
		<>
			<FooterStyled.SafeContent>
				<FooterStyled.ContainerLogoSSL>
					<FooterStyled.ContainerSecurity>
						<FooterStyled.Security>
							<FooterStyled.SecurityLogo src={FooterImages.securityLogo} />
							<FooterStyled.SecurityTextFooter>
								Segurança que você precisa para sua compra.
							</FooterStyled.SecurityTextFooter>
						</FooterStyled.Security>
						<FooterStyled.SecurityTextFooter2>
							Temos selos de segurança para cuidar de você em cada etapa da sua
							compra.
						</FooterStyled.SecurityTextFooter2>
					</FooterStyled.ContainerSecurity>

					{isMobile ? (
						<>
							<FooterStyled.SecurityTextFooter3>
								CERTIFICADOS DE SEGURANÇA
							</FooterStyled.SecurityTextFooter3>
						</>
					) : (
						<div />
					)}

					<FooterStyled.ContainerSafeLogoFooter>
						<FooterStyled.ImageSSL src={FooterImages.SSLLogo} />
						<FooterStyled.ImageSSL
							style={{ marginLeft: "10px" }}
							src={FooterImages.safeLogo}
						/>
					</FooterStyled.ContainerSafeLogoFooter>
				</FooterStyled.ContainerLogoSSL>
			</FooterStyled.SafeContent>
		</>
	);

	const copyRightContent = (): JSX.Element => (
		<>
			<FooterStyled.CopyrightContent>
				<FooterStyled.Copyright>
					Copyright © BASF {new Date().getUTCFullYear()}
				</FooterStyled.Copyright>
				<FooterStyled.Address>
					BASF S.A., Avenida Nações Unidas, 14.171, Torre Cristal, 04794-000 São
					Paulo,
					<br /> Brazil - CNPJ 48539407/0001-18
				</FooterStyled.Address>
			</FooterStyled.CopyrightContent>
		</>
	);

	return (
		<>
			{blockRoutesToShowElement.includes(window.location.pathname) ?
				<Fragment />
				:
				<FooterStyled.Container className={className}>
					{isMobile ? (
						<>
							{conectaLogo()}
							{socialMedia()}
							<Accordion
								accordionContent={account()}
								accordionTitle="MINHA CONTA"
								accordionIcon={
									<ExpandMoreIcon style={{ color: "red", width: "30px" }} />
								}
							/>
							<Accordion
								accordionContent={products()}
								accordionTitle="NOSSOS PRODUTOS"
								accordionIcon={
									<ExpandMoreIcon style={{ color: "red", width: "30px" }} />
								}
							/>
							<Accordion
								accordionContent={basfContent()}
								accordionTitle="INSTITUCIONAL"
								accordionIcon={
									<ExpandMoreIcon style={{ color: "red", width: "30px" }} />
								}
							/>
							<Accordion
								accordionContent={overview()}
								accordionTitle="INFORMAÇÕES GERAIS"
								accordionIcon={
									<ExpandMoreIcon style={{ color: "red", width: "30px" }} />
								}
							/>
							{safeContent()}
							{copyRightContent()}
						</>
					) : (
						<>
							<FooterStyled.ContainerContentDesktop>
								<FooterStyled.ContainersDesk>
									{conectaLogo()}
								</FooterStyled.ContainersDesk>
								<FooterStyled.ContainersDesk>
									<FooterStyled.ConectaTitle>MINHA CONTA</FooterStyled.ConectaTitle>
									{account()}
								</FooterStyled.ContainersDesk>
								<FooterStyled.ContainersDesk>
									<FooterStyled.ConectaTitle>
										NOSSOS PRODUTOS
									</FooterStyled.ConectaTitle>
									{products()}
								</FooterStyled.ContainersDesk>
								<FooterStyled.ContainersDesk>
									<FooterStyled.ConectaTitle>
										INSTITUCIONAL
									</FooterStyled.ConectaTitle>
									{basfContent()}
								</FooterStyled.ContainersDesk>

								<FooterStyled.ContainersDesk>
									<FooterStyled.ConectaTitle>
										INFORMAÇÕES GERAIS
									</FooterStyled.ConectaTitle>
									{overview()}
								</FooterStyled.ContainersDesk>

								<FooterStyled.ContainersDesk>
									<FooterStyled.ConectaTitle>
										REDES SOCIAIS
									</FooterStyled.ConectaTitle>
									{socialMedia()}
								</FooterStyled.ContainersDesk>
							</FooterStyled.ContainerContentDesktop>
							<hr
								style={{ borderColor: colors.neutral.gray04, marginTop: "10px" }}
							/>
							<FooterStyled.ContainersDesk>
								{safeContent()}
							</FooterStyled.ContainersDesk>
							<FooterStyled.ContainersDesk>
								{copyRightContent()}
							</FooterStyled.ContainersDesk>
						</>
					)}
				</FooterStyled.Container>
			}
		</>
	);
};

export default Footer;
