import styled from 'styled-components'
import { Drawer } from 'antd'

const CustomFullScreenModal = styled(Drawer)`
    .ant-drawer-body {
        padding: 0 !important;
    }
`

export const FullWidthModalStyled = {
    CustomFullScreenModal
}