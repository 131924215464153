import { FC, Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ContainerPage, TitlePage } from "design-system/components";
import { TermAndConditionStyled } from "./styled";
import { useTitle } from "hooks";
import { colors } from "design-system/theme";
import { ButtonProps, TermAndConditionStyledProps } from "design-system/domain";
import { Grid } from "@mui/material";
import { TermAndConditionAccordion } from "./TermAndConditionAccordion";
import { isMobile } from "react-device-detect";
import { RootState } from "store";

const RegulamentoLink =
	"https://apiagregabasf.caosdata.com.br/images/REGULAMENTO%20PROGRAMA%20AGREGA_AGRICULTOR_VF_Mar2022%20(1).pdf";

const TermAndConditionPage: FC<TermAndConditionStyledProps & ButtonProps> = ({
	className,
}) => {
	useTitle("Regulamento");
	const { user } = useSelector((state: RootState) => state);
	const navigate = useNavigate();

	return (
		<>
			<ContainerPage>
				<TitlePage title="Regulamento" />
				<TermAndConditionStyled.ContainerSubTitleText>
					<TermAndConditionStyled.SubTitleText>
						Conheça alguns dos principais pontos do regulamento. A versão
						completa pode ser acessada a partir do botão no final da página.
					</TermAndConditionStyled.SubTitleText>
				</TermAndConditionStyled.ContainerSubTitleText>

				<TermAndConditionStyled.Container>
					{!isMobile ? (
						<Grid container spacing={1}>
							<Grid md={3}>
								<TermAndConditionStyled.TermAndConditionBlock
									backgroundColor={colors.primary.orange02}
								>
									<TermAndConditionStyled.TermAndConditionBlockTitle
										color={colors.generics.white}
									>
										Programa
									</TermAndConditionStyled.TermAndConditionBlockTitle>

									<TermAndConditionStyled.TermAndConditionBlockText
										color={colors.generics.white}
									>
										Lorem ipsum dolor sit amet consectetur, adipisicing elit.
										Totam porro veritatis quaerat perferendis ab quasi
										obcaecati, impedit architecto? Quos explicabo totam itaque
										error eaque laudantium quod ex obcaecati praesentium
										inventore! Lorem ipsum dolor sit amet consectetur,
										adipisicing elit. Totam porro veritatis quaerat perferendis
										ab quasi obcaecati, impedit architecto? Quos explicabo totam
										itaque error eaque laudantium quod ex obcaecati praesentium
										inventore!
									</TermAndConditionStyled.TermAndConditionBlockText>
								</TermAndConditionStyled.TermAndConditionBlock>

								<TermAndConditionStyled.TermAndConditionBlock
									marginTop="20px"
									backgroundColor={colors.neutral.gray03}
								>
									<TermAndConditionStyled.TermAndConditionBlockTitle
										color={colors.generics.white}
									>
										Resgate
									</TermAndConditionStyled.TermAndConditionBlockTitle>

									<TermAndConditionStyled.TermAndConditionBlockText
										color={colors.generics.white}
									>
										Lorem ipsum dolor sit amet consectetur, adipisicing elit.
										Totam porro veritatis quaerat perferendis ab quasi
										obcaecati, impedit architecto? Quos explicabo totam itaque
										error eaque laudantium quod ex obcaecati praesentium
										inventore! Lorem ipsum dolor sit amet consectetur,
										adipisicing elit. Totam porro veritatis quaerat perferendis
										ab quasi obcaecati, impedit architecto? Quos explicabo totam
										itaque error eaque laudantium quod ex obcaecati praesentium
										inventore!
									</TermAndConditionStyled.TermAndConditionBlockText>
								</TermAndConditionStyled.TermAndConditionBlock>
							</Grid>
							<Grid md={6}>
								<TermAndConditionStyled.TermAndConditionBlock
									backgroundColor={colors.primary.orange02}
								>
									<TermAndConditionStyled.TermAndConditionBlockTitle
										style={{ marginTop: "-5px" }}
										color={colors.generics.white}
									>
										Regras de Pontuação
									</TermAndConditionStyled.TermAndConditionBlockTitle>

									<TermAndConditionStyled.TermAndConditionBlockText
										color={colors.generics.white}
									>
										Lorem ipsum dolor sit amet consectetur, adipisicing elit.
										Totam porro veritatis quaerat perferendis ab quasi
										obcaecati, impedit architecto? Quos explicabo totam itaque
										error eaque laudantium quod ex obcaecati praesentium
										inventore! Lorem ipsum dolor sit amet consectetur,
										adipisicing elit. Totam porro veritatis quaerat perferendis
										ab quasi obcaecati, impedit architecto? Quos explicabo totam
										itaque error eaque laudantium quod ex obcaecati praesentium
										inventore!
									</TermAndConditionStyled.TermAndConditionBlockText>

									<TermAndConditionStyled.TermAndConditionBlockText
										color={colors.generics.white}
									>
										Lorem ipsum dolor sit amet consectetur, adipisicing elit.
										Totam porro veritatis quaerat perferendis ab quasi
										obcaecati, impedit architecto? Quos explicabo totam itaque
										error eaque laudantium quod ex obcaecati praesentium
										inventore! Lorem ipsum dolor sit amet consectetur,
										adipisicing elit. Totam porro veritatis quaerat perferendis
										ab quasi obcaecati, impedit architecto? Quos explicabo totam
										itaque error eaque laudantium quod ex obcaecati praesentium
										inventore!
									</TermAndConditionStyled.TermAndConditionBlockText>

									<TermAndConditionStyled.TermAndConditionBlockText
										color={colors.generics.white}
									>
										Lorem ipsum dolor sit amet consectetur, adipisicing elit.
										Totam porro veritatis quaerat perferendis ab quasi
										obcaecati, impedit architecto? Quos explicabo totam itaque
										error eaque laudantium quod ex obcaecati praesentium
										inventore! Lorem ipsum dolor sit amet consectetur,
										adipisicing elit. Totam porro veritatis quaerat perferendis
										ab quasi obcaecati, impedit architecto? Quos explicabo totam
										itaque error eaque laudantium quod ex obcaecati praesentium
										inventore!
									</TermAndConditionStyled.TermAndConditionBlockText>
								</TermAndConditionStyled.TermAndConditionBlock>
							</Grid>
							<Grid md={3}>
								<TermAndConditionStyled.TermAndConditionBlock
									backgroundColor={colors.neutral.gray03}
								>
									<TermAndConditionStyled.TermAndConditionBlockTitle
										color={colors.generics.white}
									>
										Expiração dos Pontos
									</TermAndConditionStyled.TermAndConditionBlockTitle>

									<TermAndConditionStyled.TermAndConditionBlockText
										color={colors.generics.white}
									>
										Lorem ipsum dolor sit amet consectetur, adipisicing elit.
										Totam porro veritatis quaerat perferendis ab quasi
										obcaecati, impedit architecto? Quos explicabo totam itaque
										error eaque laudantium quod ex obcaecati praesentium
										inventore! Lorem ipsum dolor sit amet consectetur,
										adipisicing elit. Totam porro veritatis quaerat perferendis
										ab quasi obcaecati, impedit architecto? Quos explicabo totam
										itaque error eaque laudantium quod ex obcaecati praesentium
										inventore!
									</TermAndConditionStyled.TermAndConditionBlockText>
								</TermAndConditionStyled.TermAndConditionBlock>

								<TermAndConditionStyled.TermAndConditionBlock
									marginTop="20px"
									backgroundColor={colors.primary.orange02}
								>
									<TermAndConditionStyled.TermAndConditionBlockTitle
										color={colors.generics.white}
									>
										Migração de Pontos do Antigo Programa
									</TermAndConditionStyled.TermAndConditionBlockTitle>

									<TermAndConditionStyled.TermAndConditionBlockText
										color={colors.generics.white}
									>
										Lorem ipsum dolor sit amet consectetur, adipisicing elit.
										Totam porro veritatis quaerat perferendis ab quasi
										obcaecati, impedit architecto? Quos explicabo totam itaque
										error eaque laudantium quod ex obcaecati praesentium
										inventore! Lorem ipsum dolor sit amet consectetur,
										adipisicing elit. Totam porro veritatis quaerat perferendis
										ab quasi obcaecati, impedit architecto? Quos explicabo totam
										itaque error eaque laudantium quod ex obcaecati praesentium
										inventore!
									</TermAndConditionStyled.TermAndConditionBlockText>
								</TermAndConditionStyled.TermAndConditionBlock>
							</Grid>
						</Grid>
					) : (
						<TermAndConditionAccordion />
					)}
					<TermAndConditionStyled.TermAndConditionContainerButton>
						<TermAndConditionStyled.TermAndConditionBlockTextCustomized
							color={colors.neutral.gray03}
						>
							<a rel="noreferrer" target="_blank" href={RegulamentoLink}>
								<TermAndConditionStyled.TermAndConditionButtonCustomized
									className={className}
									buttonText="Confira o regulamento completo"
								/>
							</a>
						</TermAndConditionStyled.TermAndConditionBlockTextCustomized>
					</TermAndConditionStyled.TermAndConditionContainerButton>

					{user.opt_in_points ? (
						<>
							<TermAndConditionStyled.TermAndConditionBlockTextCustomized
								color={colors.neutral.gray03}
							>
								Você concordou com os termos do regulamento e já faz parte do
								programa AGREGA.
							</TermAndConditionStyled.TermAndConditionBlockTextCustomized>
						</>
					) : (
						<>
							{user.is_logged ? (
								<TermAndConditionStyled.TermAndConditionContainerButton
									style={{ borderTop: "none" }}
								>
									<TermAndConditionStyled.TermAndConditionBlockTextCustomized
										color={colors.neutral.gray03}
									>
										Para participar do programa Conecta Pontos você deve
										complementar o seu cadastro.
										<a onClick={() => navigate("/cadastro")}>
											<TermAndConditionStyled.TermAndConditionButtonCustomized2
												className={className}
												buttonText="Atualizar Cadastro"
											/>
										</a>
									</TermAndConditionStyled.TermAndConditionBlockTextCustomized>
								</TermAndConditionStyled.TermAndConditionContainerButton>
							) : (
								<Fragment />
							)}
						</>
					)}
				</TermAndConditionStyled.Container>
			</ContainerPage>
		</>
	);
};

export default TermAndConditionPage;
