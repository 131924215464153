import { FC } from "react";
import { CloseIcon } from "design-system/icons";
import { Modal } from "design-system/components";
import { RedirectComplementAccountStyled } from "./styled";

export type OptinComunicationProps = {
	handleCloseModal: () => void;
	isOpen: boolean;
};
const OptinComunication: FC<OptinComunicationProps> = ({
	handleCloseModal,
	isOpen,
}) => {
	return (
		<Modal
			header={
				<RedirectComplementAccountStyled.HeaderRow>
					<RedirectComplementAccountStyled.CloseButtom
						onClick={() => handleCloseModal()}
					>
						<CloseIcon />
					</RedirectComplementAccountStyled.CloseButtom>
				</RedirectComplementAccountStyled.HeaderRow>
			}
			content={
				<>
					<div>
						<RedirectComplementAccountStyled.ModalBody>
							<div>
								<p>
									Por favor, finalize o preenchimento de alguns dados para
									continuar.
								</p>
							</div>
							<RedirectComplementAccountStyled.RowButton>
								<RedirectComplementAccountStyled.CustomConfirmButton
									buttonText="Desejo continuar"
									eventClick={() => window.location.replace("/cadastro")}
								/>
							</RedirectComplementAccountStyled.RowButton>
						</RedirectComplementAccountStyled.ModalBody>
					</div>
				</>
			}
			titleAlign={`center`}
			open={isOpen}
			size={`SMALL`}
		/>
	);
};

export default OptinComunication;
