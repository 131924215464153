import { Http } from "../../../data/protocols/services/api";
import { HttpRegistrationRedirect } from "../../../services/account-user/redirect";
import { StorageHelper } from "../../../utils/functions/storage/storage";
import { toast } from "react-toastify";
import { BoxSign, Button } from "./styled";

interface ButtonsProps {
  campaignDetails: {
    bannerCustomLink: string;
    bannerLinkType: "NoneLink" | "RedirectToCatalog" | "CustomLink";
    name: string;
  };
  campaignOptin: {
    optinProgram: boolean | null;
    optinStatus: boolean | null;
  };
  isLogged: boolean;
  modal: boolean;
  setModal: (modal: boolean) => void;
  user: {
    document_number: string | null;
    email: string | null;
  };
}

export const Buttons = (props: ButtonsProps) => {
  const { campaignDetails, campaignOptin, modal, setModal, isLogged, user } =
    props;

  const http = new Http();
  const storageHelper = new StorageHelper();
  const httpRegistrationRedirect = new HttpRegistrationRedirect(
    http,
    storageHelper
  );

  const handleRedirectToCatalog = async () => {
    try {
      const log = await httpRegistrationRedirect.getUrlRedirect({
        data: {
          documentNumber: user.document_number as string,
          email: user.email as string,
        },
      });
      localStorage.setItem("user_redirect", "isRedirect");
      window.location.href = log.response.data;
    } catch (error: unknown) {
      toast.error("Não foi possível redirecionar para o catálogo.", {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "error_redirect_catalog",
      });
    }
  };

  const handleRedirectToCustomLink = () => {
    const customLink = campaignDetails.bannerCustomLink;

    if (customLink.includes("http")) {
      return window.open(customLink, "_blank", "noopener,noreferrer");
    }
    return window.open(`http://${customLink}`, "_blank", "noopener,noreferrer");
  };

  return (
    <BoxSign id={isLogged ? "isLogged" : "notLogged"}>
      <h3 className="text-[20px]">
        <strong>{campaignDetails.name}</strong>
      </h3>

      <div id={isLogged ? "isLogged" : "notLogged"}>
        {campaignOptin.optinProgram && campaignOptin.optinStatus ? (
          <>
            <p className="bold underline mt-2">
              VOCÊ JÁ ESTÁ CADASTRADO NESTE PROGRAMA.
            </p>
            {campaignDetails.bannerLinkType === "RedirectToCatalog" && (
              <Button $typeButton="default" onClick={handleRedirectToCatalog}>
                Resgate Agora
              </Button>
            )}
            {campaignDetails.bannerLinkType === "CustomLink" && (
              <Button $typeButton="default" onClick={handleRedirectToCustomLink}>
                Acumule Pontos
              </Button>
            )}
          </>
        ) : campaignOptin.optinProgram && !campaignOptin.optinStatus ? (
          <>
            <p className="bold underline mt-2">
              VOCÊ ESTÁ INSCRITO NO PROGRAMA, PORÉM PRECISA ACEITAR O
              REGULAMENTO.
            </p>
            <div
              className="button-rescue-now mt-1 mb-1"
              onClick={() => setModal(!modal)}
            >
              ACEITAR O REGULAMENTO
            </div>
          </>
        ) : (
          <>
            <Button $typeButton="default" onClick={() => setModal(!modal)}>
              PARTICIPAR DO PROGRAMA
            </Button>
          </>
        )}
      </div>
    </BoxSign>
  );
};
