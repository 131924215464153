import styled from "styled-components";

export const Main = styled.main`
	.back-blue {
		width: 100%;
		height: 16px;
		background: #004a98;
		padding: 0;
		@media (max-width: 1150px) {
			margin-bottom: 30px;
		}
	}

	.block-program {
		width: 50%;
		align-items: center;
		gap: 20px;
		@media (max-width: 1150px) {
			display: block;
			text-align: center;
			margin: auto;
			padding-bottom: 50px;
		}
		p {
			max-width: 400px;

			@media (max-width: 1300px) {
				max-width: 300px;
			}

			@media (max-width: 1150px) {
				max-width: 100%;
			}
		}
	}

	.block-img img {
		border: 1px solid #d2d2d2;
		border-radius: 24px;
		@media (max-width: 1366px) {
			margin-bottom: 20px;
		}
	}

	h4 {
		font-size: 42px;
		color: #25363f;
	}

	@media (max-width: 1150px) {
		.mobile-block {
			display: block;
		}
	}

	@media (max-width: 1023px) {
		.mobile-table {
			display: block;
		}
	}
`;

export const Banner = styled.div`
	background-image: url(/img/banner-elo.png);
	background-size: cover;
	background-position: center 35px;
	display: block;
	height: 672px;
	max-height: 100%;
	max-width: 100%;
	margin-top: -5px;
	@media (max-width: 500px) {
		height: 600px;
		background-position: 0;
	}

	.pdd-200 {
		padding-top: 200px;
		@media (max-width: 500px) {
			padding-top: 100px;
		}
	}

	h1 {
		color: #25363f;
		font-size: 60px;
		max-width: 500px;
		@media (max-width: 500px) {
			font-size: 45px;
		}
	}

	h2 {
		font-size: 60px;
		background: -webkit-linear-gradient(#ec0e16, #f39500);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		@media (max-width: 500px) {
			font-size: 55px;
			margin-bottom: 10px;
		}
	}

	h3 {
		font-size: 23px;
		max-width: 450px;
		color: #25363f;
	}
`;

export const BackBlue = styled.div`
	width: 50%;
	margin-top: 100px;
	background: #2a3c47;
	padding-left: 3%;
	@media (max-width: 1200px) {
		width: 100%;
		text-align: center;
		padding-left: 0;
		padding-bottom: 54px;
		margin-top: 10px;
	}

	h1 {
		padding-top: 154px;
		color: #e4b100;
		font-size: 48px;
		line-height: 48px;
		@media (max-width: 1200px) {
			padding-top: 54px;
		}
	}

	h2 {
		padding-top: 24px;
		color: #fff;
		font-size: 32px;
		@media (max-width: 1200px) {
			margin: auto;
		}
	}

	h3 {
		padding-top: 24px;
		color: #fff;
		font-size: 18px;
		max-width: 600px;
		@media (max-width: 1200px) {
			margin: auto;
		}
	}
`;

export const BackGuy = styled.div`
	width: 50%;
	margin-top: 100px;
	background-image: url(/img/elo-guy.png);
	background-position: center;
	height: 600px;
	background-size: cover;
	@media (max-width: 1200px) {
		width: 0;
		margin: auto;
		height: auto;
	}
`;

export const BackYellow = styled.div`
	background-color: #e4b100;
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding-bottom: 50px;
	@media (max-width: 1200px) {
		display: block;
		margin: auto;
	}
	.yellow-pc {
		width: 50%;
		text-align: center;
		@media (max-width: 1200px) {
			width: 100%;
			text-align: center;
		}
		img {
			@media (max-width: 1500px) {
				width: 70%;
				margin: auto;
				text-align: center;
				display: block;
			}

			@media (max-width: 1200px) {
				width: 40%;
			}

			@media (max-width: 500px) {
				width: 80%;
			}
		}
	}
	.yellow-text {
		justify-content: center;
		width: 50%;
		@media (max-width: 1200px) {
			width: 60%;
			margin: auto;
		}
		@media (max-width: 800px) {
			width: 80%;
			margin: auto;
		}
		h2 {
			padding-top: 25px;
			font-size: 48px;
			color: #004a98;
		}

		.num {
			width: 48px;
			height: 48px;
			background: #004a98;
			text-align: center;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			font-size: 28px;
			font-weight: bold;
			@media (max-width: 1100px) {
				margin: 10px auto;
			}
		}

		.num-text {
			max-width: 500px;
			@media (max-width: 1100px) {
				text-align: center;
				max-width: 100%;
			}
		}
	}

	.btn-blue {
		height: 79px;
		background: #004a98;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 56px;
		border-radius: 40px;
		font-size: 24px;
		color: #fff;
		font-weight: bold;
		max-width: 600px;
	}
`;

export const BackList = styled.div`
	color: #25363f;
	font-size: 16px;
	.old-elo {
		width: 50%;
		@media (max-width: 1023px) {
			width: 100%;
			margin-bottom: 30px;
		}
		img {
			padding-left: 20px;
		}
	}

	.white-row {
		background: #fff;
		height: 73px;
		align-items: center;
		display: flex;
		padding-left: 20px;
	}

	.gray-row {
		background: #f7f7f8;
		height: 73px;
		align-items: center;
		display: flex;
		padding-left: 20px;
	}
`;

export const BackInfo = styled.div`
	background-color: #e4b100;
	padding: 66px 0;
	margin-top: 100px;
	@media (max-width: 1023px) {
		margin-top: 10px;
	}
	h2 {
		color: #ffffff;
		font-size: 48px;
		font-weight: bold;
	}
	h3 {
		color: #ffffff;
		font-size: 24px;
		font-weight: bold;
		padding-top: 20px;
	}
	.info {
		width: 50%;
		@media (max-width: 1023px) {
			width: 100%;
		}
	}
	.info-mail {
		.label {
			font-size: 16px;
			color: #ffffff;
			line-height: 30px;
			font-weight: bold;
		}

		.info-text {
			font-size: 36px;
			color: #004a98;
			line-height: 46px;
			font-weight: bold;
			text-wrap: nowrap;
		}

		.info-hour {
			color: rgb(255, 255, 255);
			font-size: 20px;
			font-weight: bold;
			padding-top: 10px;
		}
	}
`;

export const ContainerDefault = styled.section`
	background: #2a3c47;

	h2 {
		color: #e4b100;
		letter-spacing: -0.96px;
		font-size: 48px;
		line-height: 34px;
		padding-top: 120px;
		padding-bottom: 66px;
		@media (max-width: 800px) {
			width: auto;
			line-height: 52px;
			padding: 10px;
			text-align: center;
			margin: 20px auto;
		}
	}

	.img-categories {
		width: 100%;

		@media (max-width: 1300px) {
			width: 250px;
		}
	}

	.question-box {
		margin-bottom: 16px;
		width: calc(33.3333% - 30px);
		min-height: 250px;

		@media (max-width: 1200px) {
			width: 31%;
		}

		@media (max-width: 1023px) {
			width: 100%;
			margin: auto;
			text-align: center;
			margin-top: 50px;
			min-height: auto;
		}

		.question-title {
			color: #fff;
			font-size: 24px;
			line-height: 30px;
		}

		.question-answer {
			color: #fdfdfd;
			font-size: 18px;
			line-height: 30px;
			padding-top: 20px;
			max-width: 500px;
			opacity: 0.73;
			@media (max-width: 1023px) {
				max-width: 100%;
			}
		}
	}

	.question-small-box {
		background: #25363f;
		font-size: 23px;
		line-height: 30px;
		color: #fff;
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		align-items: center;
		padding: 20px 25px;
		margin-top: 50px;
		width: calc(29.3333% - 30px);

		@media (max-width: 1200px) {
			width: 300px;
		}

		@media (max-width: 1023px) {
			width: 90%;
			display: block;
			justify-content: center;
			display: flex;
		}

		.text-small-box {
			margin-left: 20px;
			color: #fff;
		}

		a {
			display: flex;
		}

		strong {
			color: #e4b100;
		}
	}
`;
