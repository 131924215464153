/* eslint-disable sort-keys */
/* eslint-disable no-mixed-spaces-and-tabs */
import { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	ContainerPage,
	TitlePage,
	Notifications,
	NotificationError,
} from "design-system/components/index";
import { ContainerFormStyled } from "./styled";
import { useTitle } from "hooks/index";
import { Http, api } from "data/protocols/services/api";
import { StorageHelper } from "utils/functions/storage/storage";
import { StatusRequest } from "utils/consts/status-request";
import { RootState } from "store";
import { HttpRegistrationRedirect } from "services/account-user/redirect";
import { HttpRegistrationComplement } from "services/account-user/complement";
import { setOptiInsAsTrue } from "store/slices/user";
import { useNavigate } from "react-router-dom";
import { setCurrentCampaign } from "store/slices/campaign";
import ModalLogout from "modal/modal-logout";

export type RegisterPageProps = {
	campaing?:
	| undefined
	| {
		conectaProgramId?: string | undefined | null;
		description: string;
		descriptiveBanner: string;
		descriptiveCampaign: string;
		faq: string;
		howWorks: string;
		imgBanner: string;
		imgCampaign: string;
		legacyExtract?: boolean;
		mechanic: string;
		name: string;
		participantsProducts: string;
		regulation: string;
		title?: string;
	};
	isDetailCampaignFlow?: boolean;
	name?: string;
	optin?: boolean | undefined | null;
	optinStatus?: boolean | null;
	url?: boolean;
};

const RegisterPage: FC<RegisterPageProps> = ({
	url,
	name,
	campaing,
	optin,
	optinStatus,
	isDetailCampaignFlow,
}) => {
	const { user } = useSelector((state: RootState) => state);
	const [promotionCode, setPromotionCode] = useState<string | null>(null);
	const [isCheck, setIscheck] = useState<boolean>(false);
	const [isCampaing, setIsCampaing] = useState<boolean>(false);
	const [statusRequestApi, setStatusRequestApi] = useState<null | StatusRequest.ERROR>(null);
	const [customMessageError, setCustomMessageError] = useState("");
	const [disabledButtonWithCampaing, setDisabledButtonWithCampaing] = useState<boolean>(true);

	const [statusRegisterComplement, setStatusRegisterComplement] =
		useState<StatusRequest | null>(null);
	const [optIns, setOptIns] = useState({
		opt_in_points: user.opt_in_points,
		opt_in_terms_and_privacy: user.opt_in_terms_and_privacy,
	});
	const [showModalLogout, setShowModalLogout] = useState<boolean>(false);
	const [optinComunication, setOptinComunication] = useState<
		null | number | boolean
	>(user.opt_in_comunication !== null ? user.opt_in_comunication : 0);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const http = new Http();
	const httpRegistrationRedirect = new HttpRegistrationRedirect(
		http,
		new StorageHelper()
	);
	const httpRegistrationComplement = new HttpRegistrationComplement(
		http,
		new StorageHelper()
	);

	const termLink = `${process.env.REACT_APP_CONECTA_AG_URL}/regulamento-fidelidade`;
	const privacyLink = `${process.env.REACT_APP_CONECTA_AG_URL}/politica-de-privacidade`;

	useEffect(() => {
		if (!user.is_logged) {
			navigate("/programas-parceiros");
		}

		if (isDetailCampaignFlow) {
			setDisabledButtonWithCampaing(true);
		}

		if (!url) {
			setDisabledButtonWithCampaing(false);
		}
		if (user.opt_in_terms_and_privacy && user.opt_in_points) {
			setDisabledButtonWithCampaing(!!(!isCampaing && isDetailCampaignFlow));
		}
	}, [user, url]);

	useTitle("Cadastro");

	const checkHandler = () => {
		setIscheck(!isCheck);
		setOptinComunication(isCheck ? 0 : 1);
	};

	const checkCampaing = (e: any) => {
		setIsCampaing(!!e.target.checked);
		setDisabledButtonWithCampaing(!e.target.checked);
	};

	const handleChangePromotionCode = (e: any) => {
		setPromotionCode(e.target.value);
	};

	async function handleOption() {
		setStatusRequestApi(null);
		setCustomMessageError('');

		const form = {
			ConectaProgramId: campaing?.conectaProgramId,
			DocumentNumber: user.document_number,
			PromotionCode: promotionCode
		};

		const status = await api.postOptinCampaing(form)
			.then(res => {
				if (res.status === 200) {
					dispatch(setCurrentCampaign({
						id: campaing?.conectaProgramId,
						optinProgram: true,
						optinStatus: true,
					}));
				}

				return res.status;
			})
			.catch((error: any) => {
				if (error?.response?.data?.statusCode === 400) {
					setStatusRequestApi(StatusRequest.ERROR);
					setCustomMessageError(String(error?.response?.data?.message));

					return error.response.data.statusCode;
				}
			});

		return status;
	}

	const disableButton =
		!optIns.opt_in_terms_and_privacy || !optIns.opt_in_points;

	const redirectPageToCatalog = async () => {
		const res = await httpRegistrationRedirect.getUrlRedirect({
			data: {
				documentNumber: user.document_number as string,
				email: user.email as string,
			},
		});

		if (res.statusCode === 401) {
			setShowModalLogout(true);
			return;
		}

		window.location.href = res.response.data;
	}

	const fetchComplementAccount = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	): Promise<void> => {
		e.preventDefault();

		if (campaing) {
			const statusResponse = await handleOption();

			if (statusResponse !== 200)
				return;
		}

		try {
			const result = await httpRegistrationComplement.complement({
				data: {
					optInPoints: optIns.opt_in_points,
					optinTermsAndPrivacy: optIns.opt_in_terms_and_privacy,
					optinComunication: optinComunication ? 1 : 0,
				},
			});

			if (result.statusCode === 401) {
				setShowModalLogout(true);
				return;
			}
			
			if (result.statusCode === 400) {
				setStatusRequestApi(StatusRequest.ERROR);
				setCustomMessageError(String(result?.response?.data?.message));
				return;
			}

			dispatch(setOptiInsAsTrue(optinComunication === 1));
			if (result.success) {
				setStatusRegisterComplement(StatusRequest.SUCCESS);
				setTimeout(() => {
					if (campaing) {
						window.location.reload();
						return;
					}

					redirectPageToCatalog();
				}, 3000);
			}
		} catch (error: any) {
			setShowModalLogout(error?.response?.status === 401);
		}
	};

	return (
		<>
			<ModalLogout isOpen={showModalLogout} />
			{statusRegisterComplement === StatusRequest.SUCCESS && (
				<Notifications message="Cadastrado com sucesso" />
			)}
			{statusRequestApi === StatusRequest.ERROR && (
				<NotificationError position="bottom-right" message={customMessageError ? customMessageError : "Ocorreu um erro"} />
			)}

			<ContainerPage>
				<TitlePage id="realizar-adesao" title="Realizar adesão" />
				<ContainerFormStyled.ContainerForm>
					<ContainerFormStyled.FormCustomized>
						<div>
							{campaing ? (
								<>
									{!user.promotion_code && (
										<ContainerFormStyled.BoxForms>
											<ContainerFormStyled.FormLabelCustomized>
												{" "}
												Indicação/Promoção (opcional)
											</ContainerFormStyled.FormLabelCustomized>
											<ContainerFormStyled.InputPromotionCode
												value={promotionCode}
												onChange={handleChangePromotionCode}
												placeholder={"Código Promocional"}
												required={false}
												type={"text"}
											/>
										</ContainerFormStyled.BoxForms>
									)}
								</>
							) : (
								""
							)}
						</div>
						<div className="pt-2 span-special">
							<span>* Campos obrigatórios</span>
						</div>
						{(!optin || !campaing || !optinStatus) && isDetailCampaignFlow && (
							<ContainerFormStyled.ContainerCheckbox>
								<ContainerFormStyled.CheckboxCustomized
									type={"checkbox"}
									value={isCampaing}
									checked={isCampaing}
									onChange={checkCampaing}
								/>
								<ContainerFormStyled.FormLabelCheckboxCustomizedSpecial>
									<span>*</span> Aceito o regulamento do programa {name}.
								</ContainerFormStyled.FormLabelCheckboxCustomizedSpecial>
							</ContainerFormStyled.ContainerCheckbox>
						)}
						{!user.opt_in_points && (
							<ContainerFormStyled.ContainerCheckbox>
								<ContainerFormStyled.CheckboxCustomized
									type={"checkbox"}
									value={optIns.opt_in_points}
									onChange={() =>
										setOptIns({
											...optIns,
											opt_in_points: !optIns.opt_in_points,
										})
									}
								/>
								<ContainerFormStyled.FormLabelCheckboxCustomized>
									<span>*</span> Eu li e concordo com os{" "}
									<ContainerFormStyled.Link target={"_blank"} href={termLink}>
										termos e condições de uso do Conecta Pontos.
									</ContainerFormStyled.Link>
								</ContainerFormStyled.FormLabelCheckboxCustomized>
							</ContainerFormStyled.ContainerCheckbox>
						)}

						{!user.opt_in_terms_and_privacy && (
							<ContainerFormStyled.ContainerCheckbox>
								<ContainerFormStyled.CheckboxCustomized
									type={"checkbox"}
									value={optIns.opt_in_terms_and_privacy}
									onChange={() =>
										setOptIns({
											...optIns,
											opt_in_terms_and_privacy:
												!optIns.opt_in_terms_and_privacy,
										})
									}
								/>
								<ContainerFormStyled.FormLabelCheckboxCustomized>
									<span>*</span> Eu li e concordo com a{" "}
									<ContainerFormStyled.Link
										target={"_blank"}
										href={privacyLink}
									>
										política de privacidade do Conecta Pontos.
									</ContainerFormStyled.Link>
								</ContainerFormStyled.FormLabelCheckboxCustomized>
							</ContainerFormStyled.ContainerCheckbox>
						)}

						{user.opt_in_comunication === null && (
							<ContainerFormStyled.ContainerCheckbox>
								<ContainerFormStyled.CheckboxCustomized
									type={"checkbox"}
									value={optinComunication}
									checked={isCheck}
									onChange={checkHandler}
								/>
								<ContainerFormStyled.FormLabelCheckboxCustomizedSpecial>
									Aceito receber E-mails e/ou SMSs promocionais com ofertas e
									informações do Conecta Pontos.
								</ContainerFormStyled.FormLabelCheckboxCustomizedSpecial>
							</ContainerFormStyled.ContainerCheckbox>
						)}

						<ContainerFormStyled.ButtonCustomized
							eventClick={(
								e: React.MouseEvent<HTMLButtonElement, MouseEvent>
							) => fetchComplementAccount(e)}
							padding={"0.400rem 1.5rem"}
							buttonText="Salvar"
							disabled={disableButton || disabledButtonWithCampaing}
						/>
					</ContainerFormStyled.FormCustomized>
				</ContainerFormStyled.ContainerForm>
			</ContainerPage>
		</>
	);
};

export default RegisterPage;
